var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "intro-modal",
            centered: "",
            "hide-footer": "",
            size: "xl",
            "header-class": "intro-modal",
            "content-class": "intro-modal",
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("iframe", {
              attrs: {
                width: "1100",
                height: "618",
                src: "https://www.youtube.com/embed/22iGLntzgmg?autoplay=1&start=2",
                title: "YouTube video player",
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: "",
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }