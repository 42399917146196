<template>
  <b-modal
    id="modal-create-candidate"
    title="APPLY AS CANDIDATE"
    size="xl"
    centered
    hide-footer
    @hide=""
    @show=""
    :scrollable="true"
    no-enforce-focus
  >
    <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
      <div>
        <h4>Personal Information</h4>
        <b-row class="mt-2">
          <b-col cols="12" md="6">
            <b-form-group id="input-group-1" label="Your Name: *" label-for="name">
              <b-form-input
                id="name"
                type="text"
                placeholder="Enter name"
                v-model="form.name"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              id="input-group-2"
              label="Your Identification Number: *"
              label-for="identification_number"
            >
              <b-form-input
                id="identification_number"
                type="text"
                placeholder="Identification number"
                v-model="form.identification_no"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Email address: *"
              label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  required>
                </b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label="Phone No: *"
              label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.phone_no"
                  type="text"
                  placeholder="Phone no"
                  required>
                </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="LinkedIn:"
              label-for="input-4">
                <b-form-input
                  id="input-3"
                  v-model="form.linkedin_url"
                  type="url"
                  placeholder="LinkedIn URL">
                </b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label="Github: *"
              label-for="input-5">
                <b-form-input
                  id="input-5"
                  v-model="form.github_url"
                  type="url"
                  required
                  placeholder="Github URL">
                </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Address: *"
              label-for="input-3">
                <b-form-input
                  id="input-3"
                  v-model="form.address"
                  type="text"
                  placeholder="Address"
                  required>
                </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Website:"
              label-for="input-6">
                <b-form-input
                  id="input-6"
                  v-model="form.website_url"
                  type="url"
                  placeholder="Website URL">
                </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div>
              <p>About Yourself:</p>
              <ckeditor :editor="editor" v-model="form.about_self" :config="editorConfig"></ckeditor>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- <b-form-group id="input-group-3" label="Select Skill" >
                    <b-form-select v-model="form.skills" :options="skillList" size="sm" class="mt-3"></b-form-select>
                </b-form-group>
    

                <b-form-group id="input-group-4" label="Select Experience" >
                    <b-form-select v-model="form.skills.experience" :options="experienceList" size="sm" class="mt-3"></b-form-select>
                </b-form-group>
                {{form}} -->

      <div class="mt-4">
        <h4>SKILLS</h4>
        <b-row class="mt-2">
          <b-col cols="12" md="6">
            <div class="bg-light text-dark p-4">
              <b-row>
                <b-col>
                  <label for="">Topic: *</label>
                  <b-form-select
                    v-model="temp_skill"
                    :options="skillList"
                    size="sm"
                    class="mt-2"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <label for="">Experience: *</label>
                  <b-form-select
                    v-model="temp_work_exp"
                    :options="experienceList"
                    size="sm"
                    class="mt-2"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" v-if="skillMsg">
                  <div class="mt-4 text-left text-danger">{{ skillMsg }}</div>
                </b-col>
                <b-col cols="12" class="mt-2 text-right">
                  <b-button @click.prevent="addSkill(temp_skill, temp_work_exp)"
                    >ADD SKILL</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div
              class="bg-light text-dark shadow-sm p-2 mb-2"
              v-for="(skill, index) in form.skills"
              :key="index"
            >
              <b-row>
                <b-col md="3">Topic</b-col>
                <b-col md="7" class="font-weight-bold">{{
                  getTopicDisplay(skill.topic)
                }}</b-col>
                <b-col md="2" class="text-right">
                  <b-button
                    @click="skillRemove(skill.topic)"
                    type="button"
                    variant="outline-danger"
                    size="sm"
                    ><i class="fas fa-trash-alt"></i></b-button
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">Experience</b-col>
                <b-col md="9" class="font-weight-bold">{{
                  getExperienceDisplay(skill.experience)
                }}</b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="mt-4">
        <h4>Work Experience</h4>
        <b-row class="mt-2">
          <b-col cols="12" md="6">
            <div class="bg-light text-dark p-4">
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-form-group
                    label="Institute Name: *"
                    label-class="required"
                    description=""
                    
                  >
                    <b-form-input
                      type="text"
                      placeholder="Institute"
                      v-model="institute_name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="mt-2" cols="12">
                  <b-form-group
                    label="Position: *"
                    label-class="required"
                    description="">
                      <b-form-input
                        type="text"
                        placeholder="Position"
                        v-model="position"
                      ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="mt-2" cols="12">
                  <b-form-group
                    label="Description"
                    label-class="required"
                    description=""
                  >
                    <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                  </b-form-group>
                </b-col>
                <b-col class="mt-2" cols="6">
                  <b-form-group
                    label="Start Time: *"
                    label-class="required"
                    description=""
                  >
                    <b-form-input
                      type="date"
                      v-model="start_time"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="mt-2" cols="6">
                  <b-form-group
                    label="End Time"
                    label-class="required"
                    description=""
                  >
                    <b-form-input type="date" v-model="end_time"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" v-if="instituteMsg || positionMsg || start_timeMsg">
                  <div class="mt-4 text-left text-danger" v-if="instituteMsg">{{ instituteMsg }}</div>
                  <div class="mt-4 text-left text-danger" v-if="positionMsg">{{ positionMsg }}</div>
                  <div class="mt-4 text-left text-danger" v-if="start_timeMsg">{{ start_timeMsg }}</div>
                </b-col>
                <b-col cols="12">
                  <div class="mt-2 text-right">
                    <b-button
                      @click.prevent="
                        addExperience(
                          institute_name,
                          position,
                          description,
                          start_time,
                          end_time
                        )">
                      ADD EXPERIENCE</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div
              class="bg-light text-dark shadow-sm p-2 mb-2"
              v-for="(work_exp, index) in form.work_exps"
              :key="index"
            >
              <b-row>
                <b-col cols="12" md="3">
                  <b-col cols="12">Institute</b-col>
                </b-col>
                <b-col cols="12" md="7">
                   <b>{{ work_exp.institute_name }}</b>
                </b-col>

                <b-col cols="12" md="2" class="text-right">
                  <b-button
                    @click="experienceRemove(work_exp.institute_name)"
                    type="button"
                    variant="outline-danger"
                    size="sm"
                    ><i class="fas fa-trash-alt"></i></b-button
                  >
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3">
                  <b-col cols="12">Position</b-col>
                </b-col>
                <b-col cols="12" md="9">
                   <b>{{ work_exp.position }}</b>
                </b-col>
              </b-row>

              <b-row v-if="work_exp.description">
                <b-col cols="12" md="3">
                  <b-col cols="12">Description</b-col>
                </b-col>
                <b-col cols="12" md="9">
                   <div v-html="work_exp.description"></div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3">
                  <b-col cols="12">Duration</b-col>
                </b-col>
                <b-col cols="12" md="9">
                   <small>
                        <span v-if="work_exp.start_time"><b>{{ work_exp.start_time }}</b> To</span>
                        <span v-if="work_exp.end_time"><b>{{ work_exp.end_time }}</b></span>
                        <span v-else><b>PRESENT</b></span>
                      </small>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4 mb-3">
          <b-col>
            <div>
              <p>Cover Letter:</p>
              <ckeditor :editor="editor" v-model="form.cover_letter" :config="editorConfig"></ckeditor>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="CV/Resume"
              label-class="required"
              description=""
            >
              <b-form-file v-model="form.resume" plain></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div class="mt-4 text-right">
        <b-row>
          <b-col class="text-left">
            <b-button type="reset" variant="danger">RESET</b-button>
          </b-col>
          <b-col>
            <b-button type="submit" variant="primary">SUBMIT</b-button>
          </b-col>
        </b-row>
      </div>

    </b-form>
  </b-modal>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: {'Content-Type': 'multipart/form-data'}
});
export default {
  props: ["jobId"],
  data() {
    return {
      skillList: null,
      experienceList: null,
      form: {
        name: null,
        candidate_id: null,
        identification_no: null,
        email: null,
        phone_no: null,
        address: null,
        linkedin_url: null,
        github_url: null,
        website_url: null,
        about_self: null,
        resume: null,
        cover_letter: null,
        skills: [],
        work_exps: [],
        job_listing: null,
      },
      show: true,

      temp_skill: null,
      temp_work_exp: null,
      institute_name: null,
      position: null,
      description: null,
      start_time: null,
      end_time: null,
      skillMsg: null,
      instituteMsg: null,
      positionMsg: null,
      start_timeMsg: null,

      editor: ClassicEditor,
      editorConfig:{
        toolbar: {
            items: [
                "bold",
                "italic",
                "BulletedList",
                "NumberedList",
                "undo",
                "redo"
            ]
        }
      }
    };
  },
  created() {
    this.fetchSkillList();
    this.fetchExperienceList();
    this.getLength();
  },
  updated() {
    this.form.job_listing = this.jobId;
  },

  methods: {
    getLength(){
      apiClient.get("/api/v1/career/candidate/")
      .then((response)=>{
        this.form.candidate_id = parseInt(response.data.count)+parseInt(1)
      })
    },

    skillRemove(topicId) {
      var updated_skills = this.form.skills.filter(function (value) {
        return value.topic != topicId;
      });
      this.form.skills = updated_skills;
    },
    experienceRemove(institute) {
      var updated_exp = this.form.work_exps.filter(function (value) {
        return value.institute_name != institute;
      });
      this.form.work_exps = updated_exp;
    },

    getTopicDisplay(topicId) {
      var skill = this.skillList.find((skill) => skill.value == topicId);
      return skill.text;
    },

    getExperienceDisplay(expId) {
      var exp = this.experienceList.find((exp) => exp.value == expId);
      return exp.text;
    },

    fetchSkillList() {
      setTimeout(() => {
        apiClient.get("/api/v1/career/skill_list").then((resp) => {
          if (resp.status == 200) {
            this.skillList = resp.data;
          }
        });
      }, 200);
    },
    fetchExperienceList() {
      setTimeout(() => {
        apiClient.get("/api/v1/career/experience_list").then((resp) => {
          if (resp.status == 200) {
            this.experienceList = resp.data;
          }
        });
      }, 200);
    },

    addSkill(topic, wexp) {
      var found_skill = this.form.skills.find((skill) => skill.topic == topic);
      if (found_skill) {
        this.skillMsg = "Skill already added!";
        this.temp_skill = null;
        this.temp_work_exp = null;
        setTimeout(()=>{
          this.skillMsg=null
        }, 3000)
      } else if ([null, undefined, ""].includes(topic) || [null, undefined, ""].includes(wexp)) {
        this.skillMsg = "Both topic & experience must be filled up";
        this.temp_skill = null;
        this.temp_work_exp = null;
        setTimeout(()=>{
          this.skillMsg=null
        }, 3000)
      } else {
        this.form.skills.push({
          topic: topic,
          experience: wexp,
        });

        this.temp_skill = null;
        this.temp_work_exp = null;
        this.skillMsg = null;
      }
    },

    addExperience(inst,pos, desc, start_time, end_time) {
      if(!inst){
        this.instituteMsg = "Please enter the organization name!"
        setTimeout(()=>{
          this.instituteMsg = null
        }, 3000)
      }else if(!pos){
        this.positionMsg = "Please enter position field!"
        setTimeout(()=>{
          this.positionMsg = null
        }, 3000)
      }else if(!start_time){
        this.start_timeMsg = "Please enter Starting date of your job!"
        setTimeout(()=>{
          this.start_timeMsg = null
        }, 3000)
      }else{
        this.form.work_exps.push({
        institute_name: inst,
        position:pos,
        description: desc,
        start_time: start_time,
        end_time: end_time,
      });

      this.institute_name = null;
      this.position = null;
      this.description = null;
      this.start_time = null;
      this.end_time = null;
      this.instituteMsg = null;
      this.positionMsg = null;
      this.start_timeMsg = null;
      console.log(this.form.work_exps)
      }
    },

    onSubmit() {
      let formData = new FormData()
      formData.append('name',this.form.name)
      formData.append('identification_no',this.form.identification_no)
      formData.append('email',this.form.email)
      formData.append('phone_no',this.form.phone_no)
      formData.append('address',this.form.address)
      formData.append('linkedin_url',this.form.linkedin_url)

      for (let skill in this.form.skills) {
        formData.append(`skills[${skill}]topic`, this.form.skills[skill].topic)
        formData.append(`skills[${skill}]experience`, this.form.skills[skill].experience)
      }

      for (let work_exp in this.form.work_exps) {
        formData.append(`work_exps[${work_exp}]institute_name`, this.form.work_exps[work_exp].institute_name)
        formData.append(`work_exps[${work_exp}]description`, this.form.work_exps[work_exp].description)
        formData.append(`work_exps[${work_exp}]position`, this.form.work_exps[work_exp].position)
        formData.append(`work_exps[${work_exp}]start_time`, this.form.work_exps[work_exp].start_time)
        formData.append(`work_exps[${work_exp}]end_time`, this.form.work_exps[work_exp].end_time)
      }

      formData.append('github_url',this.form.github_url)
      formData.append('website_url',this.form.website_url)
      formData.append('about_self',this.form.about_self)

      if (this.form.resume) {
        formData.append('resume',this.form.resume)
      }

      formData.append('cover_letter',this.form.cover_letter)
      formData.append('job_listing',this.form.job_listing)

      apiClient.post("/api/v1/career/candidate/", formData)
      .then(response=>{
        if(response.status == 201){
            alert("Successfully submitted your application")
            this.onReset()
        }
      })

    },

    onReset() {
      this.form = {
        name: null,
        candidate_id: null,
        identification_no: null,
        email: null,
        phone_no: null,
        address: null,
        linkedin_url: null,
        github_url: null,
        website_url: null,
        about_self: null,
        resume: null,
        cover_letter: null,
        skills: [],
        work_exps: [],
        job_listing: null
      };
      this.temp_skill = null;
      this.temp_work_exp = null;
      this.job_listing = null;
    },
  },
};
</script>