<template>
  <div class="mt-4">
    <b-row>
      <b-col class="text-right">
          <b-button v-b-modal.modal-create-candidate variant="btn btn-success">
            <b>Create Candidate</b>
          </b-button>
          <CreateCandidate></CreateCandidate>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4">
        <TableCandidate></TableCandidate>
      </b-col>
    </b-row>

    
  </div>
</template>

<script>
import CreateCandidate from './CreateCandidate.vue'
import TableCandidate from './TableCandidate.vue'

export default {
  components: {
      TableCandidate,
      CreateCandidate
  },

}
</script>

<style>

</style>