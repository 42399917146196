<template>
    <div class="mt-2 p-3 article-card border-bottom" @click="showArticle(article)">
        <h4>{{ article.title }}</h4>
        <div>
            <small>
                <span class="mr-2"><i class="fas fa-calendar-alt"></i> {{ article.date }}</span>
                <span><i class="fas fa-eye"></i> {{ article.view_count }}</span>
            </small>
        </div>
    </div>
</template>

<script>
export default {
    props: ['article'],

    methods: {
        showArticle(article){
            this.$router.push({name: 'article-detail', params: { year: article.year, month: article.month,
            day: article.day, slug: article.slug, }})
        }
    }
}
</script>

<style scoped>
.article-card:hover {
    cursor: pointer;
}
</style>