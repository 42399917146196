var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          staticClass: "container",
          attrs: {
            id: "modal-view-candidate",
            title: "Candidate Info",
            size: "xl",
            centered: "",
            "hide-footer": "",
            scrollable: true,
            "no-enforce-focus": "",
          },
          on: { show: _vm.onShow },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("h4", [_vm._v("Personal Info")]),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "mt-3" }, [
                        _c("p", [_vm._v("ID")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Name")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Phone No")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Identification No")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Email")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Address")]),
                      ]),
                      _vm._v(" "),
                      _c("b-col", { staticClass: "mt-3" }, [
                        _c("p", [_vm._v(_vm._s(_vm.id))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.name))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.phone_no))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.identification_no))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.email))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.address))]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c("h4", [_vm._v("Profile")]),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c("b-col", [
                        _c("p", [_vm._v("LinkedIn")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("GitHub")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Website")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Resume")]),
                      ]),
                      _vm._v(" "),
                      _c("b-col", [
                        _c("p", [
                          _c("a", { attrs: { href: "#" } }, [
                            _vm._v(_vm._s(_vm.linkedin_url)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("a", { attrs: { href: "#" } }, [
                            _vm._v(_vm._s(_vm.github_url)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("a", { attrs: { href: "#" } }, [
                            _vm._v(_vm._s(_vm.website_url)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("a", { attrs: { href: "#" } }, [
                            _vm._v(_vm._s(_vm.resume)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c("h4", [_vm._v("About")]),
                _vm._v(" "),
                _c("p", { staticClass: "mt-3" }, [
                  _vm._v(_vm._s(_vm.about_self)),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", { staticClass: "mt-3" }, [
                _c("h4", [_vm._v("Work Experience")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "wexp" },
                  _vm._l(_vm.work_exps, function (exp) {
                    return _c("div", { key: exp.id, staticClass: "card" }, [
                      _c("p", [
                        _c("b", [_vm._v("Institute:")]),
                        _vm._v(" " + _vm._s(exp.institute_name)),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Position:")]),
                        _vm._v(" " + _vm._s(exp.position)),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("From:")]),
                        _vm._v(" " + _vm._s(exp.start_time)),
                      ]),
                      _vm._v(" "),
                      exp.end_time
                        ? _c("p", [
                            _c("b", [_vm._v("To:")]),
                            _vm._v(" " + _vm._s(exp.end_time)),
                          ])
                        : _c("p", [
                            _c("b", [_vm._v("To:")]),
                            _vm._v(" Present"),
                          ]),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("b-col", { staticClass: "mt-3" }, [
                _c("h4", [_vm._v("Skills")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "wexp" },
                  _vm._l(_vm.skills, function (skill) {
                    return _c("div", { key: skill.id, staticClass: "card" }, [
                      _c("p", [
                        _c("b", [_vm._v("Topic:")]),
                        _vm._v(" " + _vm._s(skill.topic_display)),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Experience:")]),
                        _vm._v(" " + _vm._s(skill.exp_display)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c("h4", { staticClass: "mb-3" }, [_vm._v("Cover Letter")]),
                _vm._v(" "),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.cover_letter) },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }