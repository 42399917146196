var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container mt-4 text-justify" },
    [
      _c("Nav"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "10", md: "9" } },
            _vm._l(_vm.news.news_list, function (news, index) {
              return _c("div", { key: index }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.news_details(news.slug)
                      },
                    },
                  },
                  [
                    _c(
                      "b-row",
                      { staticClass: "news-items" },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "3" } },
                                  [_c("b-img", { attrs: { src: news.image } })],
                                  1
                                ),
                                _vm._v(" "),
                                _c("b-col", { attrs: { md: "9" } }, [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "1.5rem" } },
                                    [_vm._v(_vm._s(news.title))]
                                  ),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v("DATE: 15th January 2022"),
                                  ]),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("small", [_vm._v("Views: 15K")]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c("div", { staticClass: "section-title" }, [
          _c("h4", [_vm._v("NEWS")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }