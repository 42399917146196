var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container mt-4" },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12", md: "3" } }),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
            _c(
              "div",
              { staticClass: "shadow-sm rounded p-4" },
              [
                _c("div", { staticClass: "text-center" }, [
                  _c("h2", { staticClass: "text-orange" }, [_vm._v("LOGIN")]),
                ]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { label: "Username", "label-for": "username" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "username",
                        placeholder: "username here",
                        type: "text",
                        trim: "",
                      },
                      model: {
                        value: _vm.loginForm.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "username", $$v)
                        },
                        expression: "loginForm.username",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    staticClass: "mt-4",
                    attrs: { label: "Password", "label-for": "password" },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "password",
                        placeholder: "password here",
                        type: "password",
                        trim: "",
                      },
                      model: {
                        value: _vm.loginForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "password", $$v)
                        },
                        expression: "loginForm.password",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-center mt-4" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "8", "offset-md": "2" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: _vm.onProcess,
                                  variant:
                                    "success rounded font-weight-bold w-100",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.doLogin.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm.onProcess
                                  ? _c(
                                      "span",
                                      [
                                        _c("b-spinner", {
                                          staticStyle: {
                                            width: "1.5rem",
                                            height: "1.5rem",
                                          },
                                          attrs: { small: "" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("span", [_vm._v("LOGIN")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "12", md: "3" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }