<template>
  <div class="mt-4">
    <b-row>
      <b-col class="text-right">
        <b-button variant="success" v-b-modal.modal-create-service>
          <b>Create Service</b>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <table-service></table-service>
      </b-col>
    </b-row>

    <create-service></create-service>
    <view-service></view-service>  
    <update-service></update-service>
  </div>
</template>

<script>
import TableService from './TableService.vue'
import CreateService from './CreateService.vue'
import ViewService from './ViewService.vue'
import UpdateService from './UpdateService.vue'
export default {
  components: {
    TableService,
    CreateService,
    ViewService,
    UpdateService
  },

}
</script>

<style>

</style>