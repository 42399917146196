import axios from "axios";
import ServiceBlog from "../../service/ServiceBlog.js";

export const namespaced = true

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
    baseURL: process.env.BACKEND_HOST,
  });

export const state = {
    article_list: [],
    total_article: null,

    article: null,
    selected_article_slug: null
}

export const mutations = {
    SET_ARTICLES(state, articles){
        state.article_list = articles
    },
    SET_ARTICLE_COUNT(state, count) {
        state.total_article = count
    },

    ADD_ARTICLE(state, article) {
        state.article_list.push(article)
    },

    DELETE_ARTICLE(state, slug) {
        state.article_list = state.article_list.filter(article_obj => article_obj.slug != slug)
    },

    UPDATE_ARTICLE (state, article) {
        state.article_list.filter(article_obj => {
            if (article_obj.slug == article.slug) {
                Object.assign(article_obj, article)
            }
        })
    },
    SET_ARTICLE (state, article) {
        state.article = article
    },
    SELECT_ARTICLE (state, slug) {
        state.selected_article_slug = slug
    }
}

export const actions = {
    getArticles({commit}, {perPage, page}){
        return ServiceBlog.getArticleList(perPage, page)
            .then(resp => {
                if (resp.status == 200) {
                    commit("SET_ARTICLES", resp.data.results)
                    commit("SET_ARTICLE_COUNT", resp.data.count)
                    return resp.data.results
                }
            })
            .catch(err => {

            })
    },

    createArticle({commit}, article){
        return ServiceBlog.createArticle(article)
            .then(resp => {
                if (resp.status == 201) {
                    commit("ADD_ARTICLE", resp.data)
                    Vue.toasted.show(`Successfully created article`, { className: 'bg-success' })
                }
            })
            .catch(err => {

            })
    },

    updateArticle({commit}, article){
        return ServiceBlog.updateArticle(article)
            .then(resp => {
                if (resp.status == 200) {
                    commit("UPDATE_ARTICLE", resp.data)
                    Vue.toasted.show(`Successfully updated article`, { className: 'bg-info' })
                }
            })
            .catch(err => {

            })
    },

    getArticle({commit}, slug){
        return ServiceBlog.getArticle(slug)
            .then(resp => {
                if (resp.status == 200) {
                    commit("SET_ARTICLE", resp.data)
                    return resp.data
                }
            })
            .catch(err => {

            })
    },

    deleteArticle({commit},slug){
        ServiceBlog.deleteArticle(slug).then((response)=>{
            if(response.status==204){
                commit('DELETE_ARTICLE',slug)
                Vue.toasted.show(`Successfully Deleted Article`, { className: 'bg-danger' })
            }
        })
    },

    getTags(){
        return ServiceBlog.getTags().then((response)=>{
            if(response.status==200){
                return response.data
            }
        })
    },

    getStatus(){
        return ServiceBlog.getStatus().then((response)=>{
            if(response.status==200){
                return response.data
            }
        })
    },

    getAllArticles(){
        return ServiceBlog.getAllArticles().then((response)=>{
            if(response.status == 200){
                console.log(response.data.results)
                return response.data.results
            }
        })
    }

}