<template>
  <b-modal
    id="modal-update-article"
    title="Update Article"
    size="xl"
    centered
    hide-footer
    @show="onShow"
    :scrollable="true"
    no-enforce-focus
  >
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col>
          <b-form-group id="input-group-1" label="Title: *" label-for="title" class="mt-2">
              <b-form-input id="title" type="text" placeholder="Enter Title" v-model="form.title" required></b-form-input>
            </b-form-group>
            
          <b-form-group id="input-group-2" label= "Description: *" label-for= "Description" class="mt-2">
            <ckeditor :editor="editor" v-model="form.description" :config="editorConfig"></ckeditor>
          </b-form-group>

          <b-form-group id="input-group-3" label= "Youtube Video Id: " label-for= "youtube_video_id" class="mt-2">
            <b-form-input id= "youtube_video_id" type= "text" v-model="form.ytb_video_id" placeholder= "Video id(optional)"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-4" label= "Tags" label-for= "select" class="mt-2">
            <b-form-select id="select" v-model="form.tags" :options="options" multiple :select-size="4"></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-5" label= "Status" label-for= "status-select" class="mt-2">
            <b-form-select id="status-select" v-model="form.status" :options="status_list"></b-form-select>
          </b-form-group>

        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col class="text-right">
          <b-button style="width:130px;" type= "submit" variant= "success" :disabled="onProgress">
           <span v-if="!onProgress">Submit</span>
           <span v-else>
              <b-spinner :class="'spinner'"></b-spinner>
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import axios from 'axios';
import {mapActions, mapState} from 'vuex';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data(){
    return {
      onProgress: false,
      form: {
        title: null,
        description: null,
        ytb_video_id: null,
        tags: [],
        status: null,
      },
      options: [],
      status_list: [],
      editor: ClassicEditor,
      editorConfig:{
        toolbar: {
            items: [
                "bold",
                "italic",
                "BulletedList",
                "NumberedList",
                "undo",
                "redo"
            ]
        }
      }
    }
  },
  
  methods: {
     ...mapActions("blog", ["updateArticle", "getArticle"]),

    onSubmit(){
      this.onProgress = true
      this.updateArticle(this.form)
        .then(resp => {
          this.onReset()
          this.$bvModal.hide("modal-update-article")
          this.onProgress = false
        })
    },

    onShow () {
        this.getArticle(this.blog.selected_article_slug)
            .then(resp => {
                Object.assign(this.form, this.blog.article)
            })
    },

    onReset(){
      this.form.title = null,
      this.form.description = null,
      this.form.ytb_video_id = null,
      this.form.tags = [],
      this.form.status = null
    },
  },
  computed: {
      ...mapState(["blog"])
  },

  created(){

    this.$store.dispatch('blog/getTags').then(tags=>{
      this.options = []
      tags.forEach(tag => {
        this.options.push({
          value: tag.id,
          text: tag.slug
        })
      })
    }),

    this.$store.dispatch('blog/getStatus').then((status)=>{
      this.status_list = []
      status.forEach(stat => {
        this.status_list.push({
          value: stat[0],
          text: stat[1] 
        })
      })
    })
  },

}
</script>

<style scoped>
  .spinner {
    height: 1.2rem;
    width: 1.2rem;
  }
</style>