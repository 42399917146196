<template>
  <div class="mt-4">
      <h2>Dashboard here</h2>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>