
import ServiceBlog from "../../service/ServiceBlog.js";

export const namespaced = true



export const state = {
    service: null,
    service_list: [],
    total_service: null,
    selected_service_id: null
}

export const mutations = {
    ADD_SERVICES(state, response_data){
        state.service_list = response_data.results,
        state.total_service = response_data.count
    },

    SELECT_SERVICE_ID(state, id){
        state.selected_service_id = id
        console.log(state.selected_service_id)
    },

    SELECT_SERVICE(state, service){
        state.service = service
    },

    REMOVE_SERVICE(state, id){
        state.service_list = state.service_list.filter(service =>{
            return service.id != id
        })
    },

    ADD_SERVICE(state, service){
        state.service_list.push(service)
    },

    UPDATE_SERVICE(state, id){
        state.service_list.filter(service =>{
            if(service.id == id){
                console.log('updated')
            }
        })
    },

    SERVICE_BY_SLUG(state){
        console.log('Got service by slug')
    }
}

export const actions = {
    getServices({commit},{perPage,page}){
        ServiceBlog.getServices(perPage,page).then((response)=>{
            if(response.status == 200){
                commit('ADD_SERVICES', response.data)
            }
        })
    },

    getService({commit}, id){
        return ServiceBlog.getService(id).then((response)=>{
            if(response.status == 200){
                commit('SELECT_SERVICE', response.data)
                return response.data
            }
        })
    },

    deleteService({commit}, id){
        return ServiceBlog.deleteService(id).then((response)=>{
            if(response.status == 204){
                Vue.toasted.show(`Successfully deleted Service`, { className: 'bg-danger' })
                commit('REMOVE_SERVICE', id)
                return response.status
            }
        })
    },

    createService({commit}, data){
        return ServiceBlog.createService(data.formData).then((response)=>{
            if(response.status == 201){
                Vue.toasted.show(`Successfully created Service`, { className: 'bg-success' })
                commit('ADD_SERVICE', data.service)
                return response.status
            }
        })
    },

    updateService({commit}, data){
        return ServiceBlog.updateService(data.formData, data.id).then((response)=>{
            if(response.status == 200){
                Vue.toasted.show(`Successfully updated Service`, { className: 'bg-success' })
                commit('UPDATE_SERVICE', data.id)
                return response.status
            }
        })
    },

    getServiceBySlug({commit}, slug){
        return ServiceBlog.getServiceBySlug(slug).then((response)=>{
            if(response.status == 200){
                commit('SERVICE_BY_SLUG')
                return response.data
            }
        })
    }
}