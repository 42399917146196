var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "p-2 bg-white linear-bg shadow-sm" },
        [
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("b-img", {
                staticClass: "custom-img",
                attrs: { src: _vm.imgUrl, alt: "Title" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "8" } },
            [
              _c("b-row", { staticClass: "products-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c("b-row", [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.description) },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }