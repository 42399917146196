var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "testimonials" }, [
      _c("div", [
        _c("div", { staticClass: "card shadow-sm mr-4 mb-4 text-center" }, [
          _c("img", {
            staticClass: "card-img-top",
            attrs: { src: _vm.testimonial.profile_photo, alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-2" }, [
            _c("h5", { staticStyle: { color: "#f27932" } }, [
              _vm._v("\n              " + _vm._s(_vm.testimonial.name) + " "),
              _c("br"),
              _vm._v(" "),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.testimonial.company_name) + " "),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", [
                _c("small", [_vm._v(_vm._s(_vm.testimonial.designation))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-2" }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-left",
                staticStyle: { "font-size": "13px", color: "gray" },
              },
              [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.testimonial.description) +
                    "\n            "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }