var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "products-area",
      staticStyle: { position: "relative" },
      attrs: { id: "products" },
    },
    [
      _c("Nav"),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-md-6" }, [
            _c("div", { staticClass: "section-title" }, [
              _c("h4", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("Products / Portfolio"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-6" }, [
            _c(
              "div",
              {
                staticClass: "section-title text-right",
                staticStyle: { "margin-right": "1.2rem" },
              },
              [
                _c(
                  "b-link",
                  { staticClass: "w-30", attrs: { to: { path: "/product/" } } },
                  [
                    _c("b-button", { staticClass: "see-all-button w-100" }, [
                      _vm._v(_vm._s(_vm.$t("See All"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.allProducts.length == 0
          ? _c(
              "div",
              [
                _c(
                  "b-row",
                  _vm._l(6, function (index) {
                    return _c(
                      "b-col",
                      { key: -index, attrs: { cols: "12", md: "2" } },
                      [
                        _c(
                          "content-loader",
                          { attrs: { viewBox: "0 0 200 400", height: "400" } },
                          [
                            _c("rect", {
                              attrs: {
                                x: "0",
                                y: "0",
                                rx: "5",
                                ry: "5",
                                width: "500",
                                height: "500",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.allProducts.length > 0
          ? _c(
              "div",
              [
                _c(
                  "b-row",
                  _vm._l(_vm.allProducts, function (product) {
                    return _c(
                      "b-col",
                      {
                        key: product.id,
                        attrs: { cols: "12", md: "2" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.product_details(product.slug)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "product-card mb-4",
                          attrs: { src: product.logo, alt: product.title },
                        }),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }