var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "services-area",
      staticStyle: { background: "url('/static/img/bg_tech.png')" },
      attrs: { id: "news" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-6" }, [
            _c(
              "div",
              {
                staticClass: "section-title text-right",
                staticStyle: { "margin-right": "1.2rem" },
              },
              [
                _c(
                  "b-link",
                  { staticClass: "w-30", attrs: { to: { path: "/news/" } } },
                  [
                    _c("b-button", { staticClass: "see-all-button w-100" }, [
                      _vm._v("\n                    SEE ALL\n                "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.all_news.length > 0
          ? _c(
              "div",
              [
                _c(
                  "carousel",
                  {
                    attrs: {
                      id: "owl-carousel",
                      autoplay: false,
                      autoplayHoverPause: false,
                      center: false,
                      nav: false,
                      items: 3,
                      autoplayTimeout: 5000,
                      dots: false,
                    },
                  },
                  [
                    _c("template", { slot: "prev" }, [
                      _c("span", { staticClass: "news-carousal-previous" }, [
                        _c("i", { staticClass: "fas fa-angle-left fa-2x" }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.all_news, function (news, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "bg-white shadow-sm mr-4",
                            staticStyle: { height: "18rem" },
                          },
                          [
                            _c("div", { staticClass: "p-2" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.news_details(news.slug)
                                    },
                                  },
                                },
                                [
                                  _c("b-img", {
                                    staticClass: "img-style",
                                    attrs: { src: news.image },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {}, [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(news.title) +
                                        "\n                      "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _vm.all_news.length > 3
                      ? _c("template", { slot: "next" }, [
                          _c("span", { staticClass: "news-carousal-next" }, [
                            _c("i", {
                              staticClass: "fas fa-angle-right fa-2x",
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-12 col-md-6" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h4", { staticClass: "title" }, [_vm._v("NEWS")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }