import Vue from "vue";
import Vuex from "vuex";
import * as blog from './modules/blog.js'
import * as candidate from './modules/candidate.js'
import * as service from './modules/service.js'
import * as product from './modules/product.js'
import * as client from './modules/client.js'
import * as testimonial from './modules/testimonial.js'
import * as news from './modules/news.js'
import * as casestudy from './modules/casestudy.js'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    blog,
    candidate,
    service,
    product,
    client,
    testimonial,
    news,
    casestudy
  },
});
