<template>
    <div>
        <div id="intro" class="section-intro" style="background: url('/static/img/pattern-randomized.svg')">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-5 text-center">
                        <img src="/static/img/illustration.svg" width="800" height="100%" alt="SyncDots">
                    </div>
                    <div class="col-12 col-md-7 mt-5">
                        <div class="align-middle animate__animated animate__fadeIn">
                            <h2 style="color:#2a424e;font-size: 35px;">{{ $t("Providing Tech Solutions and Training") }}</h2>
                            <p style="color:gray;" class="mt-3">
                                {{ $t("Empowering Businesses with Cloud and Mobile App Solutions. We are your strategic partner in crafting cutting-edge technology solutions that drive efficiency, innovation, and growth across industries.") }}
                            </p>
                        </div>
                        <div class="mt-4 animate__animated animate__fadeInRight">
                            <!-- <button v-b-modal.intro-modal class="btn btn-know-us mr-2">
                                <i class="fas fa-play"></i>
                                <b>WHY US</b>
                            </button> -->
                            <a href="#contact" class="btn btn-getintouch page-scroll">
                                <b>{{ $t("Contact Us") }}</b>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <modal-intro-vue></modal-intro-vue>
        </div>
    </div>
</template>

<script>
import ModalIntroVue from './ModalIntroVue.vue'

export default {
    components: {
        ModalIntroVue
    }
}
</script>

<style>

</style>