<template>
    <div class="mt-4">
        <b-row>
            <b-col class="text-right">
                <b-button variant="success" v-b-modal.modal-create-product>
                    <b>Create Product</b>
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <table-product></table-product>
            </b-col>
        </b-row>
        
        <view-product></view-product>
        <create-product></create-product>
        <update-product></update-product>
    </div>
</template>

<script>
import TableProduct from "./TableProduct.vue"
import ViewProduct from "./ViewProduct.vue"
import CreateProduct from "./CreateProduct.vue"
import UpdateProduct from "./UpdateProduct.vue"
export default {
    components: {
        TableProduct,
        ViewProduct,
        CreateProduct,
        UpdateProduct
    }
}
</script>
