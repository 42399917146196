var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "services-area",
      staticStyle: {
        background: "url('/static/img/bg_tech.png')",
        position: "relative",
      },
      attrs: { id: "services" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "section-title text-center" }, [
              _c("h4", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("Our Services"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.allServices.length == 0
          ? _c(
              "div",
              [
                _c(
                  "b-row",
                  _vm._l(6, function (index) {
                    return _c(
                      "b-col",
                      { key: -index, attrs: { cols: "12", md: "2" } },
                      [
                        _c(
                          "content-loader",
                          { attrs: { viewBox: "0 0 200 400", height: "400" } },
                          [
                            _c("rect", {
                              attrs: {
                                x: "0",
                                y: "0",
                                rx: "5",
                                ry: "5",
                                width: "500",
                                height: "500",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.allServices.length > 0
          ? _c(
              "div",
              { staticClass: "align-middle text-center" },
              _vm._l(_vm.allServices, function (service) {
                return _c(
                  "div",
                  {
                    key: service.id,
                    staticClass: "d-inline-block mr-4",
                    staticStyle: { height: "200px", width: "200px" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.service_details(service.slug)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "service-card",
                      attrs: { src: service.logo, alt: service.title },
                    }),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }