
import ServiceBlog from "../../service/ServiceBlog.js";

export const namespaced = true



export const state = {
    news: null,
    news_list: [],
    total_news: null,
    selected_news_id: null
}

export const mutations = {
    ADD_NEWSES(state, response_data){
        state.news_list = response_data.results,
        state.total_news = response_data.count
    },

    SELECT_NEWS_ID(state, id){
        state.selected_news_id = id
    },

    SELECT_NEWS(state, news){
        state.news = news
    },

    REMOVE_NEW(state, id){
        state.news_list = state.news_list.filter(news =>{
            return news.id != id
        })
    },

    ADD_NEWS(state, news){
        state.news_list.push(news)
    },

    UPDATE_NEWS(state, id){
        state.news_list.filter(news =>{
            if(news.id == id){
                console.log('updated')
            }
        })
    },

    NEWS_BY_SLUG(state){
        console.log('Got service by slug')
    }
}

export const actions = {
    getNewses({commit},{perPage,page}){
        ServiceBlog.getNewses(perPage,page).then((response)=>{
            if(response.status == 200){
                commit('ADD_NEWSES', response.data)
            }
        })
    },

    getNews({commit}, id){
        return ServiceBlog.getNews(id).then((response)=>{
            if(response.status == 200){
                commit('SELECT_NEWS', response.data)
                return response.data
            }
        })
    },

    deleteNews({commit}, id){
        return ServiceBlog.deleteNews(id).then((response)=>{
            if(response.status == 204){
                Vue.toasted.show(`Successfully deleted News`, { className: 'bg-danger' })
                commit('REMOVE_NEWS', id)
                return response.status
            }
        })
    },

    createNews({commit}, data){
        return ServiceBlog.createNews(data.formData).then((response)=>{
            if(response.status == 201){
                Vue.toasted.show(`Successfully created News`, { className: 'bg-success' })
                commit('ADD_NEWS', data.news)
                return response.status
            }
        })
    },

    updateNews({commit}, data){
        return ServiceBlog.updateNews(data.formData, data.id).then((response)=>{
            if(response.status == 200){
                Vue.toasted.show(`Successfully updated News`, { className: 'bg-success' })
                commit('UPDATE_NEWS', data.id)
                return response.status
            }
        })
    },

    getNewsBySlug({commit}, slug){
        return ServiceBlog.getNewsBySlug(slug).then((response)=>{
            if(response.status == 200){
                commit('NEWS_BY_SLUG')
                return response.data
            }
        })
    }
}