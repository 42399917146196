<template>
    <div class="container mt-4">
        <Nav></Nav>
        <!-- Component Jobs -->
        <div class="row">
            <div class="col-12 col-md-6" v-for="index in 2" :key="-index" v-show="jobList.length == 0">
                <content-loader viewBox="0 0 400 600" height="220">
                    <rect x="0" y="0" rx="10" ry="10" width="400" height="220" />
                </content-loader>
            </div>

            <div class="col-12 col-md-6" v-for="job in jobList" :key="job.id">
                <div class="shadow-sm rounded p-3">
                    <h4 style="color:#f27932;">{{ job.title }}</h4>

                    <div class="mt-3">
                        <i class="fas fa-book" style="color:#f27932;"></i> Skills Required <br>
                        <b>{{ job.required_skills }}</b>
                    </div>

                    <div class="mt-2">
                        <i class="fas fa-user-tie" style="color:#f27932;"></i> Experience Required <br>
                        <b>{{ job.experience }}</b>
                    </div>

                    <div class="mt-4 text-right">
                        <b-button variant="outline-primary" size="md" @click="assignId(job.id)" v-b-modal.modal-create-candidate>APPLY</b-button>
                    </div>
                </div>
            </div>
        </div>
        <CreateCandidate :jobId="jobId"/>
    </div>
</template>

<script>
import Nav from "../../Nav.vue";
import CreateCandidate from './CreateCandidate.vue'
import axios from 'axios';
import {
  ContentLoader,
} from 'vue-content-loader'

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
});

export default {
    components: {
        Nav,
        ContentLoader,
        CreateCandidate
    },

    data () {
        return {
            jobList: [],
            jobId: null,
        }
    },

    created () {
        this.fetchJobList()
    },

    methods: {
        assignId(id){
           this.jobId = id
        },
        fetchJobList () {
            setTimeout(() => {
                apiClient.get('/api/v1/career/job-listing/')
            .then(resp=>{
                if (resp.status == 200) {
                    this.jobList = resp.data.results
                }
            })
            }, 200);
            
        }
    }
}
</script>

<style>

</style>