<template>
  <b-nav
    class="navbar navbar-light navbar-expand-lg navbar-custom bg-white shadow-sm fixed-top p-2"
  >
    <div class="container">
      <router-link to="/" :class="'navbar-brand page-scroll'">
        <img
          class="p-0"
          src="/static/img/syncdots_logo.png"
          alt="SyncDots"
          height="35"
        />
      </router-link>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item ml-2">
            <router-link :class="'nav-link bold page-scroll'" to="/#services">
              <b>{{ $t("Services") }}</b>
            </router-link>
          </li>
          <li class="nav-item ml-2">
            <router-link :class="'nav-link bold page-scroll'" to="/product">
              <b>{{ $t("Products") }}</b>
            </router-link>
          </li>
          <li class="nav-item ml-2">
            <b-link to="/blog/" :class="'nav-link bold page-scroll'">
              <b>{{ $t("Blog") }}</b>
            </b-link>
          </li>
          <li class="nav-item ml-2">
            <b-link to="/news/" :class="'nav-link bold page-scroll'">
              <b>{{ $t("News") }}</b>
            </b-link>
          </li>
          <li class="nav-item ml-2">
            <router-link :class="'nav-link bold page-scroll'" to="/#contact">
              <b>{{ $t("Contact") }}</b>
            </router-link>
          </li>
          <!-- <li class="nav-item ml-2">
            <b-link to="/team/" :class="'nav-link bold page-scroll'">
              <b>{{ $t("Team") }}</b>
            </b-link>
          </li> -->

          <li class="nav-item ml-2 mr-2 mt-1">
            <a
              class="p-1 pl-2"
              style="
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                cursor: pointer;
              "
              :style="getActiveCss('en')"
              @click="changeLanguage('en')"
            >
              <b>{{ $t("EN") }}</b>
            </a>
            <a
              class="p-1 pr-2"
              style="
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                cursor: pointer;
              "
              :style="getActiveCss('id')"
              @click="changeLanguage('id')"
            >
              <b>{{ $t("ID") }}</b>
            </a>
          </li>

          <li class="nav-item ml-2">
            <a class="get-in-touch" onclick="$crisp.push(['do', 'chat:open'])">
              <b
                ><i class="far fa-comments"></i> <b>{{ $t("Live Chat") }}</b></b
              >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </b-nav>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    changeLanguage(lang) {
      localStorage.setItem("language", lang);
      this.$i18n.locale = lang;
    },
    getActiveCss(lang) {
      if (lang === localStorage.getItem("language")) {
        return "background-color:#f27932;color:white;"; // success color
      }
      return "background-color:#FFF1E9;color:#f27932;";
    }
  },
};
</script>

<style scoped>
.nav-link {
  color: #f27932;
}
</style>