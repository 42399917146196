var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "products-area",
      staticStyle: { position: "relative" },
      attrs: { id: "case-studies" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-6" }, [
            _c(
              "div",
              {
                staticClass: "section-title text-right",
                staticStyle: { "margin-right": "1.2rem" },
              },
              [
                _c(
                  "b-link",
                  {
                    staticClass: "w-30",
                    attrs: { to: { path: "/case-study/" } },
                  },
                  [
                    _c("b-button", { staticClass: "see-all-button w-100" }, [
                      _vm._v(" SEE ALL "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.caseStudies.length > 0
          ? _c(
              "div",
              [
                _c(
                  "carousel",
                  {
                    attrs: {
                      id: "owl-carousel",
                      autoplay: false,
                      autoplayHoverPause: false,
                      center: false,
                      nav: false,
                      items: 3,
                      autoplayTimeout: 5000,
                      dots: false,
                    },
                  },
                  [
                    _c("template", { slot: "prev" }, [
                      _c(
                        "span",
                        { staticClass: "casestudy-carousal-previous" },
                        [_c("i", { staticClass: "fas fa-angle-left fa-2x" })]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.caseStudies, function (case_study, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "mt-2 mr-4 bg-white rounded shadow-sm p-2",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.case_study_details(case_study.slug)
                            },
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c("b-img", {
                                    staticClass: "casestudy-img-style",
                                    attrs: { src: case_study.image },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-col", { attrs: { md: "12" } }, [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#f27932" } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(case_study.title) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.caseStudies.length > 2
                      ? _c("template", { slot: "next" }, [
                          _c(
                            "span",
                            { staticClass: "casestudy-carousal-next" },
                            [
                              _c("i", {
                                staticClass: "fas fa-angle-right fa-2x",
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-12 col-md-6" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h4", { staticClass: "title" }, [_vm._v("CASE STUDIES")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }