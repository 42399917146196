<template>
  <section id="news" class="services-area"
    style="background: url('/static/img/bg_tech.png');"
  >
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="section-title">
              <h4 class="title">NEWS</h4>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="section-title text-right" style="margin-right:1.2rem">
              <b-link :to="{ path: '/news/' }" class="w-30">
                  <b-button class="see-all-button w-100">
                      SEE ALL
                  </b-button>
              </b-link>
            </div>
          </div>
        </div>

        <div v-if="all_news.length > 0">
          <carousel
          id="owl-carousel"
          :autoplay="false" 
          :autoplayHoverPause="false" 
          :center="false" 
          :nav="false"
          :items="3"
          :autoplayTimeout="5000"
          :dots="false"
          >
              <template slot="prev">
                <span class="news-carousal-previous">
                  <i class="fas fa-angle-left fa-2x"></i>
                </span>
              </template>
                <div v-for="news,index in all_news" :key="index">
                  <div class="bg-white shadow-sm mr-4" style="height:18rem">
                    <div class="p-2">
                      <a  href="#" @click.prevent="news_details(news.slug)">
                        <b-img class="img-style" :src="news.image">
                        </b-img>
                        <span class="">
                            {{news.title}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              <!-- <div class="m-2" style="height:20rem">
                <a  href="#" @click.prevent="news_details(news.news_list[0].slug)">
                  <b-img style="width:8rem" :src="'news.image'">
                  </b-img>
                  <span class="text-justify decor">
                      HELLO This is the Title for now. Lets Enjoy...HELLO This is the Title for now. Lets Enjoy...HELLO This is the Title for now. Lets Enjoy...
                  </span>
                </a>
              </div>
              <div class="m-2" style="height:10rem">
                <b-link :to="{ path: '/news-detail/' }">
                  <b-img :src="'https://placeimg.com/200/200/any?3'">
                  </b-img>                        
                  <span class="text-justify decor">
                      HELLO This is the Title for now. Lets Enjoy...HELLO This is the Title for now. Lets Enjoy...HELLO This is the Title for now. Lets Enjoy...
                  </span>
                </b-link>
              </div> -->
              <template slot="next" v-if="all_news.length>3">
                <span class="news-carousal-next">
                  <i class="fas fa-angle-right fa-2x"></i>
                </span>
              </template>
          </carousel>
        </div>


    <!-- <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="totalPage"
      @change="fetchServices"
      use-router
      class="float-right mt-2"
    >
    </b-pagination-nav> -->
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import carousel from 'vue-owl-carousel'

export default {
  data(){
    return {
      perPage: 10,
      all_news: [],
    }
  },
  components: {
    carousel
  },
  computed: {
    ...mapState(["news"]),
    page() {
      return parseInt(this.$route.query.page) || 1;
    },

    totalPage() {
      if (this.news.total_news % this.perPage !== 0) {
        return this.news.total_news / this.perPage + 1;
      }
      return this.news.total_news / this.perPage;
    },
  },
  methods: {
    ...mapActions("news", ["getNewses"]),

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getValues() {
      this.news.news_list.forEach(news => {
        this.all_news.push(news)
      });
    },
    fetchNewses() {
      this.getNewses({
        perPage: this.perPage,
        page: this.$route.query.page || 1,
      });
    },

    news_details(slug) {
      this.$router.push({
        name: "news-detail",
        params: {
          slug: slug,
        },
      });
    },
  },
  created() {
    this.fetchNewses()
    setTimeout(() => {
      this.getValues();
    }, 500);
  },
};
</script>

<style>
.custom-left-margin {
  margin: 0 0 0 14px;
}
.custom-float-left { 
    animation-name: custom-float-left;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}
@keyframes custom-float-left {
    0% { transform: translate(0rem,  10rem); }
    100%   { transform: translate(0rem, 0rem); }   
}
.decor {
  color: black !important;
  font-size: 1.3rem;
}
.img-style {
  width:auto;
  height:12rem;
  border-radius: 10px;
  margin: 0.5rem 0;
}
.news-carousal-previous {
  position: absolute;
  top: 10rem;
  margin-left: -5rem;
  cursor: pointer;
  font-size: 1.5rem;
}
.news-carousal-previous:hover {
  color: #f27932;
}
.news-carousal-next {
  position: absolute;
  top: 10rem;
  margin-left: 70rem;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 1;
}
.news-carousal-next:hover {
  color: #f27932;
}
</style>