
import ServiceBlog from "../../service/ServiceBlog.js";

export const namespaced = true



export const state = {
    client_list: []
}

export const mutations = {
    ADD_CLIENTS(state, clients){
        state.client_list = clients
    }
}

export const actions = {
    getClients({commit}){
        ServiceBlog.getClients().then((response)=>{
            if(response.status == 200){
                commit('ADD_CLIENTS', response.data.results)
            }
        })
    }
}