var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "our-team mt-4" },
    [
      _c("Nav"),
      _vm._v(" "),
      _c("div", { staticClass: "container mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-4 text-right" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-success btn-sm",
                  attrs: { to: "/career/" },
                },
                [_c("b", [_vm._v("JOIN US")])]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm._l(4, function (index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.member_list.length == 0,
                      expression: "member_list.length == 0",
                    },
                  ],
                  key: -index,
                  staticClass: "col-12 col-md-3 mt-2",
                },
                [
                  _c(
                    "content-loader",
                    { attrs: { viewBox: "0 0 400 600", height: "400" } },
                    [
                      _c("rect", {
                        attrs: {
                          x: "0",
                          y: "0",
                          rx: "10",
                          ry: "10",
                          width: "400",
                          height: "400",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.member_list, function (member) {
              return _c(
                "div",
                { key: member.id, staticClass: "col-12 col-md-3" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "member-card shadow-sm animate__animated animate__fadeIn mt-2 p-2 text-center",
                      staticStyle: { height: "100%" },
                    },
                    [
                      _c("img", {
                        staticClass: "img-member",
                        attrs: { src: member.image, alt: member.name },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("h5", [_vm._v(_vm._s(member.name))]),
                        _vm._v(" "),
                        _c("small", [_vm._v(_vm._s(member.designation))]),
                      ]),
                      _vm._v(" "),
                      member.email ||
                      member.contact_no ||
                      member.contact_no ||
                      member.linkedin
                        ? _c("div", { staticClass: "text-left m-0 p-0" }, [
                            member.email
                              ? _c("small", [
                                  _c("i", {
                                    staticClass: "fas fa-envelope-open",
                                  }),
                                  _vm._v(
                                    "\n               " +
                                      _vm._s(member.email) +
                                      " "
                                  ),
                                  _c("br"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            member.contact_no
                              ? _c("small", [
                                  _c("i", { staticClass: "fas fa-mobile-alt" }),
                                  _vm._v(
                                    "\n               " +
                                      _vm._s(member.contact_no) +
                                      " "
                                  ),
                                  _c("br"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            member.linkedin
                              ? _c("span", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: member.linkedin,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fab fa-linkedin fa-lg",
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-8" }, [
      _c("h4", { staticClass: "title" }, [_vm._v("Our Team")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }