var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("Nav"),
      _vm._v(" "),
      _vm._l(_vm.articles, function (article) {
        return _c(
          "div",
          { key: article.title, staticClass: "mt-3" },
          [_c("ArticleCard", { attrs: { article: article } })],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-end mt-4" },
        [
          _c("b-pagination-nav", {
            attrs: {
              "link-gen": _vm.linkGen,
              "number-of-pages": _vm.totalPage,
              "use-router": "",
            },
            on: { change: _vm.fetchArticle },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }