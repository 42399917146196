<template>
    <div>
        <b-modal
        id="modal-create-service"
        title="Create Service"
        size="xl"
        centered
        hide-footer
        @hide="onHide"
        @show=""
        :scrollable="true"
        no-enforce-focus>
            <b-form @submit.prevent="onSubmit">
                <b-form-group class="mt-3" id="input-group-1" label="Title:" label-for="input-1">
                    <b-form-input
                        id="input-1"
                        v-model="form.title"
                        placeholder="Web Development"
                        required>
                        </b-form-input>
                </b-form-group>

                <b-form-group class="mt-3" id="input-group-2" label="Description:" label-for="input-2">
                    <ckeditor :editor="editor" v-model="form.description" :config="editorConfig"></ckeditor>
                </b-form-group>

                <b-form-group class="mt-3" id="input-group-2" label="Brief:" label-for="input-2">
                    <ckeditor :editor="editor" v-model="form.brief" :config="editorConfig"></ckeditor>
                </b-form-group>

                <b-form-file class="mt-3" v-model="form.logo" plain></b-form-file>
                <div class="mt-3">Selected file: {{ form.logo ? form.logo.name : '' }}</div>

                <b-button class="float-right" style="width:130px;" type="submit" variant="success" :disabled="onProgress">
                    <span v-if="!onProgress">Submit</span>
                    <span v-else>
                      <b-spinner :class="'spinner'"></b-spinner>
                    </span>
                </b-button>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default{
    data(){
        return{
            onProgress: false,
            form:{
                title: null,
                description: null,
                brief: null,
                logo: null
            },
            editor: ClassicEditor,
            editorConfig:{
            toolbar: {
                items: [
                    "bold",
                    "italic",
                    "BulletedList",
                    "NumberedList",
                    "undo",
                    "redo"
                    ]
                }
            }
        }
    },

    methods: {
        ...mapActions("service", ["createService", "getServices"]),
        onSubmit(){
            this.onProgress = true
            let formData = new FormData()
            formData.append('title', this.form.title)
            formData.append('description', this.form.description)
            formData.append('brief', this.form.brief)
            formData.append('logo', this.form.logo)

            const data = {
                formData: formData,
                service: this.form
            }

            this.createService(data).then((response)=>{
                if(response == 201){
                    this.onReset()
                    this.$bvModal.hide("modal-create-service")
                    this.onProgress = false
                }
            })


        },

        onHide(){
            this.getServices()
        },

        onReset(){
            this.form.title = null,
            this.form.description = null,
            this.form.brief = null,
            this.form.logo = null
        },
    }
}
</script>

<style scoped>
  .spinner {
    height: 1.2rem;
    width: 1.2rem;
  }
</style>