<template>
  <div>
    <b-table
      small
      striped
      hover
      bordered
      :items="candidate.candidate_list"
      :fields="fields"
      show-empty
      @row-hovered="rowHovered"
      @row-unhovered="rowUnHovered"
    >
      <template v-slot:cell(actions)="row">
        <div v-show="selected_row == row.item.id">
          <b-button
            size="sm"
            variant="outline-info"
            v-b-modal.modal-view-candidate
            @click="onView(row.item.id)"
            ><i class="fas fa-eye"></i
          ></b-button>
          <b-button
            size="sm"
            variant="outline-info"
            v-b-modal.modal-update-candidate
            @click="onUpdate(row.item.id)"
            ><i class="fas fa-pen-alt"></i
          ></b-button>
          <b-button
            variant="outline-danger"
            size="sm"
            @click="onDelete(row.item.id)"
            ><i class="fas fa-trash-alt"></i
          ></b-button>
        </div>
      </template>
    </b-table>
    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="totalPage"
      @change="fetchCandidates"
      use-router
      class="float-right"
    >
    </b-pagination-nav>

    <UpdateCandidate></UpdateCandidate>
    <ViewCandidate></ViewCandidate>
  </div>
</template>


<script>
import { mapActions, mapState, mapMutations } from "vuex";
import UpdateCandidate from "./UpdateCandidate.vue";
import ViewCandidate from "./ViewCandidate.vue";

export default {
  components: {
    UpdateCandidate,
    ViewCandidate,
  },
  data() {
    return {
      selected_row: null,
      perPage: 10,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
          class: "text-center w-5",
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          class: "text-left w-15",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          class: "text-left w-10",
        },
        {
          key: "phone_no",
          label: "Phone No.",
          sortable: true,
          class: "text-left w-7",
        },
        {
          key: "identification_no",
          label: "Identification No",
          sortable: true,
          class: "text-left w-15",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: true,
          class: "text-center w-15",
        },
      ],
    };
  },

  computed: {
    ...mapState(["candidate"]),
    page() {
      return parseInt(this.$route.query.page) || 1;
    },

    totalPage() {
      if (this.candidate.total_candidate % this.perPage !== 0) {
        return this.candidate.total_candidate / this.perPage + 1;
      }
      return this.candidate.total_candidate / this.perPage;
    },
  },

  methods: {
    ...mapActions("candidate", ["getCandidates", "deleteCandidate"]),
    ...mapMutations("candidate", ["SELECT_CANDIDATE"]),

    rowHovered(record, index) {
      this.selected_row = record.id;
    },

    rowUnHovered(record, index) {
      this.selected_row = null;
    },

    onUpdate(id) {
      this.SELECT_CANDIDATE(id);
    },

    onView(id) {
      this.SELECT_CANDIDATE(id);
    },

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },

    fetchCandidates() {
      this.getCandidates({
        perPage: this.perPage,
        page: this.$route.query.page || 1,
      });
    },

    onDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Confirm delete Candidate?", {
          title: "DELETE ARTICLE",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "CONFIRM",
          cancelTitle: "CANCEL",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteCandidate(id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },

  created() {
    this.fetchCandidates();
  },
};
</script>