var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container mt-4" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _vm.caseStudy
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("h2", { staticStyle: { color: "#f27932" } }, [
                          _vm._v(_vm._s(_vm.caseStudy.title)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "mt-2" }, [
                          _c("small", [_vm._v(_vm._s(_vm.caseStudy.modified))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("small", [_vm._v("Views 15K")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c("b-img", {
                            staticClass: "m-4",
                            staticStyle: { height: "20rem" },
                            attrs: { src: _vm.caseStudy.image },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "text-justify" }, [
                          _vm._v(_vm._s(_vm.caseStudy.description)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-col", { attrs: { md: "1" } }),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c(
                "b-row",
                { staticClass: "custom-card" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center mt-2", attrs: { md: "12" } },
                    [_vm._v("Our Services")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      staticStyle: { height: "25rem", "overflow-y": "auto" },
                      attrs: { md: "12" },
                    },
                    _vm._l(_vm.service.service_list, function (service, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.service_details(service.slug)
                              },
                            },
                          },
                          [
                            _c("b-img", {
                              staticClass: "sidebar-img",
                              attrs: { src: service.logo },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }