<template>
  <div>
    <Nav></Nav>

    <intro-content></intro-content>
    <service-content> </service-content>
    <product-content></product-content>

    <section id="contact" class="contact-area">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="section-title">
              <h4 class="title">{{ $t("Contact Us") }}</h4>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <b-row class="mt-4">
          <b-col md="3" cols="12">
            <b-row>
              <b-col md="12" class="text-left">
                <span class="fa-stack fa-2x mb-2" style="color: #f27932;">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fas fa-phone fa-stack-1x fa-inverse"></i>
                </span>
              </b-col>
              <div style="">
                <b-col md="12" style="color: #f27932;">Call Us</b-col>
                <b-col md="12">+62-8218-0000-247</b-col>
                <!-- <b-col md="12" style="color: #f27932;">Poland Contact</b-col>
                <b-col md="12">+48-798-071-640</b-col> -->
              </div>
            </b-row>
          </b-col>
          <b-col md="6" cols="12">
            <b-row>
              <b-col md="12" class="text-left">
                <span class="fa-stack fa-2x mb-2" style="color: #f27932;">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fas fa-map-marker-alt fa-stack-1x fa-inverse"></i>
                </span>
              </b-col>
              <b-col md="12" style="color: #f27932;">Indonesia Office</b-col>
              <b-col md="12">
                Ruko Crystal no 2, Jl. Gading Golf Boulevard, Gading Serpong, Kec. Klp. Dua, Kabupaten Tangerang, Banten 15810
              </b-col>
              <!-- <b-col md="12" style="color: #f27932;">Bangladesh Office</b-col>
              <b-col md="12">Chandgaon R/A, Chandgaon 4212, Chattogram</b-col> -->
            </b-row>
          </b-col>
          <b-col md="3" cols="12">
            <b-row >
              <b-col md="12">
                <span class="fa-stack fa-2x mb-2" style="color: #f27932;">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="far fa-envelope fa-stack-1x fa-inverse"></i>
                </span>
              </b-col>
              <b-col md="12">contact@syncdots.id</b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <!-- <a class="back-to-top" href="#" style="display: block"
        ><i class="fas fa-arrow-up"></i
      ></a> -->
    </section>
  </div>
</template>

<script>
import Nav from "../Nav.vue";
import { mapActions, mapState } from "vuex";
import IntroContent from "./IntroContent.vue";
import ProductContent from "./ProductContent.vue";
import ServiceContent from "./ServiceContent.vue";
import TestimonialCard from "./TestimonialCard.vue";
import carousel from "vue-owl-carousel";
import NewsContent from "./news/NewsContent.vue"
import CaseStudyContent from "./casestudy/CaseStudyContent.vue"

export default {
  components: {
    Nav,
    IntroContent,
    ServiceContent,
    ProductContent,
    carousel,
    TestimonialCard,
    NewsContent,
    CaseStudyContent
  },
  computed: {
    ...mapState(["client", "testimonial"]),
  },

  methods: {
    ...mapActions("client", ["getClients"]),
    ...mapActions("testimonial", ["getTestimonials"]),
  },
  created() {
    this.getClients();
    this.getTestimonials();
  },
};
</script>

<style scoped>
.client-logo {
  margin: 10px;
  width: auto;
  height: 50px;
}

.client-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card {
  padding: 20px;
  margin: 10px;
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  text-align: justify;
  border: none;
  border-radius: 5%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-container {
  padding: 2px 16px;
}

.pro-img {
  padding: 2px;
  border-radius: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.contact-icon {
  background-color: #f27932;
  color: #f27932;
}
</style>>

