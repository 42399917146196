<template>
  <div class="container mt-4">
    <b-row>
      <b-col cols="12" md="3"></b-col>
      <b-col cols="12" md="6">
        <div class="shadow-sm rounded p-4">
          <div class="text-center">
            <h2 class="text-orange">LOGIN</h2>
          </div>
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              placeholder="username here"
              type="text"
              v-model="loginForm.username" trim>
            </b-form-input>
          </b-form-group>

          <b-form-group
            class="mt-4"
            label="Password"
            label-for="password"
          >
            <b-form-input
              id="password"
              placeholder="password here"
              type="password"
              v-model="loginForm.password" trim>
            </b-form-input>
          </b-form-group>

          <div class="text-center mt-4">
            <b-row>
              <b-col cols="12" md="8" offset-md="2">
                <b-button 
                  @click.prevent="doLogin"
                  :disabled="onProcess"
                  variant="success rounded font-weight-bold w-100"
                >
                  <span v-if="onProcess">
                    <b-spinner style="width: 1.5rem; height: 1.5rem;" small></b-spinner>
                  </span>
                  <span v-else>LOGIN</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export default {
  data () {
    return {
      loginForm: {
        username: null,
        password: null
      },
      onProcess: false,
      
    }
  },

  methods : {
    doLogin () {
      this.onProcess = true

      axios.post('/api/v1/auth/login/', this.loginForm)
      .then(resp => {
        if (resp.status == 200) {
          window.location.replace('/syncpanel/')
          this.onProcess = false
        }
      })
      .catch(err => {
        this.onProcess = false
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>
.text-orange {
  color: #F27932;
}
</style>