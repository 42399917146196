var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.modal-create-article",
                      modifiers: { "modal-create-article": true },
                    },
                  ],
                  attrs: { variant: "btn btn-success" },
                },
                [_c("b", [_vm._v("Create Article")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-row", [_c("b-col", [_c("TableArticle")], 1)], 1),
      _vm._v(" "),
      _c("ArticleCreate"),
      _vm._v(" "),
      _c("ArticleUpdate"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }