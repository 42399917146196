<template>
  <section
    id="services"
    class="services-area"
    style="background: url('/static/img/bg_tech.png'); position: relative"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title text-center">
            <h4 class="title">{{$t("Our Services")}}</h4>
          </div>
          <!-- section title -->
        </div>
        <!-- <div class="col-lg-6">
          <div class="section-title text-right">
            <b-link :to="{ path: '' }" class="w-30">
                <b-button class="see-all-button w-100">
                    SEE ALL
                </b-button>
            </b-link>
          </div>
        </div> -->
      </div>

      <div v-if="allServices.length == 0">
        <b-row>
          <b-col
              cols="12"
              md="2"
              v-for="index in 6"
              :key="-index"
            >
              <content-loader viewBox="0 0 200 400" height="400">
                <rect x="0" y="0" rx="5" ry="5" width="500" height="500" />
              </content-loader>
          </b-col>
        </b-row>
      </div>

      <div v-if="allServices.length > 0" class="align-middle text-center">  
        <div
          class="d-inline-block mr-4"
          style="height: 200px; width: 200px;"
          v-for="service in allServices"
          :key="service.id"
          @click.prevent="service_details(service.slug)"
        >
          <img :src="service.logo" :alt="service.title" class="service-card">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import carousel from "vue-owl-carousel";
import { ContentLoader } from "vue-content-loader";

export default {
  data() {
    return {
      perPage: 10,
      allServices: [],
    };
  },
  components: {
    carousel,
    ContentLoader,
  },
  computed: {
    ...mapState(["service"]),
    page() {
      return parseInt(this.$route.query.page) || 1;
    },

    totalPage() {
      if (this.service.total_service % this.perPage !== 0) {
        return this.service.total_service / this.perPage + 1;
      }
      return this.service.total_service / this.perPage;
    },
  },
  methods: {
    ...mapActions("service", ["getServices"]),
    getValues() {
      // this.title = this.service.service_list
      this.service.service_list.forEach((service) => {
        this.allServices.push(service);
      });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },

    fetchServices() {
      this.getServices({
        perPage: this.perPage,
        page: this.$route.query.page || 1,
      });
    },

    service_details(slug) {
      this.$router.push({
        name: "service-detail",
        params: {
          slug: slug,
        },
      });
    },
  },
  created() {
    this.fetchServices();
    setTimeout(() => {
      this.getValues();
    }, 500);
  },
};
</script>

<style scoped>
.service-carousal-previous {
  position: absolute;
  top: 14rem;
  margin-left: -4rem;
  cursor: pointer;
  font-size: 1.5rem;
}
.service-carousal-previous:hover {
  color: #f27932;
}
.service-carousal-next {
  position: absolute;
  top: 14rem;
  margin-left: 70rem;
  cursor: pointer;
  font-size: 1.5rem;
  /* border: 2px solid #f27932 !important; */
}
.service-carousal-next:hover {
  color: #f27932;
}
.service-card {
  background-size: cover;
  border-radius: 10px;
  font-size: 25px;
  transition: all 0.3s ease-in-out;
}
.service-card:hover {
  transform: scale(1.04);
  cursor: pointer;
}
</style>