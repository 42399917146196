var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Nav"),
      _vm._v(" "),
      _c(
        "b-container",
        [
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.title,
                  expression: "!title",
                },
              ],
              staticClass: "mt-4",
            },
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "content-loader",
                    { attrs: { viewBox: "0 0 200 400", height: "400" } },
                    [
                      _c("rect", {
                        attrs: {
                          x: "0",
                          y: "0",
                          rx: "5",
                          ry: "5",
                          width: "400",
                          height: "400",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { md: "8" } },
                [
                  _c(
                    "content-loader",
                    { attrs: { viewBox: "0 0 200 400", height: "400" } },
                    [
                      _c("rect", {
                        attrs: {
                          x: "0",
                          y: "0",
                          rx: "5",
                          ry: "5",
                          width: "500",
                          height: "30",
                        },
                      }),
                      _vm._v(" "),
                      _c("rect", {
                        attrs: {
                          x: "0",
                          y: "35",
                          rx: "5",
                          ry: "5",
                          width: "500",
                          height: "300",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c(
                "b-col",
                { attrs: { md: "4", cols: "12" } },
                [
                  _c(
                    "b-img",
                    _vm._b(
                      {
                        staticClass: "w-100 zoom rounded",
                        attrs: { src: _vm.logo },
                      },
                      "b-img",
                      _vm.mainProps,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { md: "8", cols: "12" } }, [
                _c("h4", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
                _vm._v(" "),
                _c("p", {
                  staticClass: "mt-3",
                  domProps: { innerHTML: _vm._s(_vm.description) },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }