<template>
  <section>
      <div class="container">
          <h4 class="title">Message from Founder</h4>

          <div class="row animate__animated animate__fadeIn">
              <div class="col-12 col-md-3 mt-3">
                  <img :src="imageFounder" alt="Ohid" class="img-founder shadow-sm">
              </div>

              <div class="msg-section col-12 col-md-9 text-center d-flex align-items-center mt-3">
                  <p class="msg-founder">
                      "As a dreamer & learner, I had always dreamed
                        of building a company which would help the earth to be
                        a better place, even if by an inch. In SyncDots, we try
                        take this vision as a sacred goal in our works. Everything
                        we do here MUST serve for the benefits of the mother
                        earth directly or indirectly. For this vision, technologies
                        & businesses are our tools to make it alive." <br>
                        <small><b>- Ohidul Alam, CTO & Founder</b></small>
                  </p>
              </div>
          </div>
      </div>
  </section>
</template>
<script>
export default {
    props: ["imageFounder"]
}
</script>

<style scoped>
    .title {
        font-size: 30px;
        color: #f27932;
    }
    .msg-founder {
        font-size: 22px;
        font-style: italic;
    }
    .img-founder {
        border-radius: 10px;
        width: 250px;
    }
    .msg-section {
        background: linear-gradient(to right, #fffcfa, #fff6f0) ;
        border-radius: 10px;
    }
</style>