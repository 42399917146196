var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mt-2 p-3 article-card border-bottom",
      on: {
        click: function ($event) {
          return _vm.showArticle(_vm.article)
        },
      },
    },
    [
      _c("h4", [_vm._v(_vm._s(_vm.article.title))]),
      _vm._v(" "),
      _c("div", [
        _c("small", [
          _c("span", { staticClass: "mr-2" }, [
            _c("i", { staticClass: "fas fa-calendar-alt" }),
            _vm._v(" " + _vm._s(_vm.article.date)),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("i", { staticClass: "fas fa-eye" }),
            _vm._v(" " + _vm._s(_vm.article.view_count)),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }