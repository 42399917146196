var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            [
              _c("b-table", {
                attrs: {
                  small: "",
                  striped: "",
                  hover: "",
                  bordered: "",
                  items: _vm.product.product_list,
                  fields: _vm.fields,
                  "show-empty": "",
                },
                on: {
                  "row-hovered": _vm.rowHovered,
                  "row-unhovered": _vm.rowUnHovered,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(brief)",
                    fn: function (row) {
                      return [
                        _c(
                          "span",
                          { domProps: { innerHTML: _vm._s(row.item.brief) } },
                          [_vm._v(_vm._s(row.item.brief))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(logo)",
                    fn: function (row) {
                      return [
                        _c(
                          "b-link",
                          { attrs: { href: row.item.logo, target: "_blank" } },
                          [_vm._v("Show")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(actions)",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selected_row === row.item.id,
                                expression: "selected_row === row.item.id",
                              },
                            ],
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.modal-view-product",
                                    modifiers: { "modal-view-product": true },
                                  },
                                ],
                                attrs: { size: "sm", variant: "outline-info" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onView(row.item.id)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-eye" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.modal-update-product",
                                    modifiers: { "modal-update-product": true },
                                  },
                                ],
                                attrs: { size: "sm", variant: "outline-info" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onUpdate(row.item.id)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-pen-alt" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "outline-danger",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDelete(row.item.id)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-trash-alt" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("b-pagination-nav", {
                staticClass: "float-right",
                attrs: {
                  "link-gen": _vm.linkGen,
                  "number-of-pages": _vm.totalPage,
                  "use-router": "",
                },
                on: { change: _vm.fetchProducts },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }