<template>
    <div>
        <b-row class="p-2 bg-white linear-bg shadow-sm">
            <b-col md="4">
                <b-img :src="imgUrl" class="custom-img" alt="Title"></b-img>
            </b-col>
            <b-col md="8">
                <b-row class="products-title">{{ title }}</b-row>
                <b-row><span v-html="description"></span></b-row>
            </b-col>
        </b-row>
        <!-- <div class="product-card linear-bg shadow-sm p-3 bg-white mt-4 animate__animated animate__fadeIn"
            :class="checkRoute()? '':''">
            <div class="products-icon" :class="checkRoute()? 'w-50 mr-2':'w-100'">
                <img :src="imgUrl" :alt="title" height="auto" width="100%">
            </div>
            <div :class="checkRoute()? 'custom-media-body':'mt-2'">
                <h4 class="products-title">{{ title }}</h4>
                <span class="text" v-html="description"></span>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    props: ["title", "description", "imgUrl"],
    methods: {
        checkRoute(){
            if (this.$route.name=="product") return true
            else return false
        }
    }
};
</script>

<style scoped>
.custom-img {
    padding: 10px 0px;
    width: 30rem;
    height: 10rem;
}
.products-title {
    padding-top:4px;
    color: #f27932;
    font-size: 18px;
    text-transform: uppercase;
}
.linear-bg {
    border-radius: 5px;
    color: gray;
    transition: all 0.3s ease-in-out;
}
/* .linear-bg:hover .products-title {
    color: white;
} */
.linear-bg:hover {
    cursor: pointer;
    /* background-color: #f27932; */
    border: 1px solid #f27932;
    transform: scale(1.03);
    /* color: white !important; */
}

</style>