var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-create-article",
        title: "Create Article",
        size: "xl",
        centered: "",
        "hide-footer": "",
        scrollable: true,
        "no-enforce-focus": "",
      },
      on: { hide: function ($event) {}, show: function ($event) {} },
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-1",
                        label: "Title: *",
                        "label-for": "title",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "title",
                          type: "text",
                          placeholder: "Enter Title",
                          required: "",
                        },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        id: "input-group-2",
                        label: "Description: *",
                        "label-for": "Description",
                      },
                    },
                    [
                      _c("ckeditor", {
                        attrs: { editor: _vm.editor, config: _vm.editorConfig },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        id: "input-group-3",
                        label: "Youtube Video Id: ",
                        "label-for": "youtube_video_id",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "youtube_video_id",
                          type: "text",
                          placeholder: "Video id(optional)",
                        },
                        model: {
                          value: _vm.form.ytb_video_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ytb_video_id", $$v)
                          },
                          expression: "form.ytb_video_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        id: "input-group-4",
                        label: "Tags",
                        "label-for": "select",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "select",
                          options: _vm.options,
                          multiple: "",
                          "select-size": 4,
                        },
                        model: {
                          value: _vm.form.tags,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tags", $$v)
                          },
                          expression: "form.tags",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        id: "input-group-5",
                        label: "Status",
                        "label-for": "status-select",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "status-select",
                          options: _vm.status_list,
                        },
                        model: {
                          value: _vm.form.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mt-3 text-right" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticStyle: { width: "130px" },
                      attrs: {
                        type: "submit",
                        variant: "success",
                        disabled: _vm.onProgress,
                      },
                    },
                    [
                      !_vm.onProgress
                        ? _c("span", [_vm._v("Submit")])
                        : _c(
                            "span",
                            [_c("b-spinner", { class: "spinner" })],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }