<template>
    <div class="container mt-4">
      <Nav></Nav>
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title">
            <h4 class="title">{{ $t("Products / Portfolio") }}</h4>
          </div>
        </div>
      </div>

      <div class="row" v-if="product.product_list.length > 0">
        <div class="col-lg-6 float-up mt-2" v-for="product in product.product_list" :key="product.id">
          <div class="pr-4" @click.prevent="product_details(product.slug)">
            <product-card
              :title="product.title"
              :description="product.brief"
              :img-url="product.logo"
            ></product-card>
          </div>
        </div>
      </div>
  
    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="totalPage"
      @change="fetchProducts"
      use-router
      class="float-right mt-4"
    >
    </b-pagination-nav>
    </div>
</template>

<script>
import Nav from "../../components/Nav.vue";
import { mapState, mapActions } from "vuex";
import ProductCard from "../../components/web/ProductCard.vue";
import carousel from "vue-owl-carousel";

export default {
  data() {
    return {
      perPage: 20,
    }
  },
  components: {
    Nav,
    ProductCard,
    carousel,
  },
  computed: {
    ...mapState(["product"]),

    totalPage() {
      if (this.product.total_product % this.perPage !== 0) {
        return this.product.total_product / this.perPage + 1;
      }
      return this.product.total_product / this.perPage;
    },
  },
  methods: {
    ...mapActions("product", ["getProducts"]),
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },

    fetchProducts() {
      this.getProducts({
        perPage: this.perPage,
        page: this.$route.query.page || 1,
      })
    },
    product_details(slug) {
      this.$router.push({
        name: "product-detail",
        params: {
          slug: slug,
        },
      });
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>

<style>

.float-up { 
    animation-name: float-up;
    animation-duration: .8s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    z-index: 1;
}
 
@keyframes float-up {
    0% { transform: translate(0,  10rem); }
    100%   { transform: translate(0, 0rem); }   
}
</style>