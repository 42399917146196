<template>
    <div>
        <Nav></Nav>
        <b-container>
            <h3 class="mt-3">{{ article.title }}</h3>
            <div>
                <small>
                    <span class="mr-2"><i class="fas fa-calendar-alt"></i> {{ article.date }}</span>
                    <span><i class="fas fa-eye"></i> {{ article.view_count }}</span>
                </small>
            </div>
            <span class="mt-3" v-html="article.description"></span>
        </b-container>
    </div>
</template>

<script>
import Nav from "../../Nav.vue";
import { mapActions } from 'vuex'
export default{
    props: ['year','month','day','slug'],
    components: {
        Nav
    },
    data(){
        return{
            article: null
        }
    },
    methods:{
        ...mapActions("blog", ["getArticle"])
    },
    created(){
        this.getArticle(this.slug).then((response)=>{
            this.article = response
        })
    }
}
</script>