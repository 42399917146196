
import ServiceBlog from "../../service/ServiceBlog.js";

export const namespaced = true



export const state = {
    candidate_list: [],
    total_candidate: null,
    selected_candidate_id: null,
    candidate: null
}

export const mutations = {
    SELECT_CANDIDATE(state,id){
        state.selected_candidate_id = id
    },

    UPDATE_CANDIDATE(state,candidate_obj){
        state.candidate_list.filter(candidate =>{
            if(candidate.id == candidate_obj.id){
                Object.assign(candidate, candidate_obj)
            }
        })
    },

    ADD_CANDIDATE(state,candidate){
        console.log(candidate)
        state.candidate_list.push(candidate)
    },

    SET_CANDIDATES(state, list_of_candidates){
        state.candidate_list = list_of_candidates
        
    },

    SET_CANDIDATE(state, candidate){
        state.candidate = candidate
    },

    SET_CANDIDATE_COUNT(state, count){
        state.total_candidate = count
       
    },

    REMOVE_CANDIDATE(state,id){
        state.candidate_list = state.candidate_list.filter(candidate => {return candidate.id != id})
    }
}

export const actions = {

    getCandidates({commit},{perPage,page}){
        ServiceBlog.getCandidates(perPage,page).then((response)=>{
            if(response.status == 200){
                commit('SET_CANDIDATES', response.data.results)
                commit('SET_CANDIDATE_COUNT', response.data.count)
            }
        })
    },

    getSkills(){
        return ServiceBlog.getSkills().then((resp) => {
            if (resp.status == 200) {
              return resp.data;
            }
          });
    },

    getExperiences(){
        return ServiceBlog.getExperience().then((resp) => {
            if (resp.status == 200) {
              return resp.data;
            }
          });
    },

    getLength(){
        return ServiceBlog.getLength().then((response)=>{
            if(response.status == 200){
                return response.data.count
            }
        })
    },

    jobListings(){
        return ServiceBlog.getJobListings().then((response)=>{
            if(response.status == 200){
                return response.data.results
            }
        })
    },

    createCandidate({commit},data){
        return ServiceBlog.createCandidate(data.formData).then((response=>{
            if(response.status == 201){
                commit("ADD_CANDIDATE", data.candidate)
                Vue.toasted.show(`Successfully created new candidate`, { className: 'bg-success' })
                return response
            }
        }))
    },

    updateCandidate({commit},data){
        return ServiceBlog.updateCandidate(data.formData, data.candidate).then((response=>{
            if(response.status == 200){
                commit("UPDATE_CANDIDATE", data.candidate)
                Vue.toasted.show(`Successfully created new candidate`, { className: 'bg-success' })
                return response
            }
        }))
    },

    deleteCandidate({commit},id){
        return ServiceBlog.deleteCandidate(id).then((response)=>{
            if(response.status == 204){
                commit('REMOVE_CANDIDATE', id)
                Vue.toasted.show(`Successfully deleted candidate`, { className: 'bg-delete' })
            }
        })
    },

    getCandidate({commit},id){
        return ServiceBlog.getCandidate(id).then(response=>{
            if(response.status == 200){
                commit('SET_CANDIDATE', response.data)
                return response.data
            }
        })
    }
}