<template>
  <b-modal
    id="modal-update-candidate"
    title="Update Candidate"
    size="xl"
    centered
    hide-footer
    @hide=""
    @show="onShow"
    :scrollable="true"
    no-enforce-focus
  >
    <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
      <div>
        <h4>Candidate's Information</h4>
        <b-row class="mt-2">
          <b-col cols="12" md="6">
            <b-form-group id="input-group-1" label="Your Name: *" label-for="name">
              <b-form-input
                id="name"
                type="text"
                placeholder="Enter name"
                v-model="form.name"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              id="input-group-2"
              label="Your Identification Number: *"
              label-for="identification_number"
            >
              <b-form-input
                id="identification_number"
                type="text"
                placeholder="Identification number"
                v-model="form.identification_no"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Email address: *"
              label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  required>
                </b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label="Phone No: *"
              label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.phone_no"
                  type="text"
                  placeholder="Phone no"
                  required>
                </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="LinkedIn:"
              label-for="input-4">
                <b-form-input
                  id="input-3"
                  v-model="form.linkedin_url"
                  type="url"
                  placeholder="LinkedIn URL">
                </b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label="Github: *"
              label-for="input-5">
                <b-form-input
                  id="input-5"
                  v-model="form.github_url"
                  type="url"
                  required
                  placeholder="Github URL">
                </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Address: *"
              label-for="input-3">
                <b-form-input
                  id="input-3"
                  v-model="form.address"
                  type="text"
                  placeholder="Address"
                  required>
                </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Website:"
              label-for="input-6">
                <b-form-input
                  id="input-6"
                  v-model="form.website_url"
                  type="url"
                  placeholder="Website URL">
                </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div>
              <p class="mb-2">About Yourself:</p>
              <ckeditor :editor="editor" v-model="form.about_self" :config="editorConfig"></ckeditor>
            </div>
          </b-col>
        </b-row>
        <b-row >
            <b-col>
                <p class="mt-2">Applying for</p>
                <b-form-select v-model="form.job_listing" :options="jobs" size="sm" class="mt-3"></b-form-select>
            </b-col>
        </b-row>
      </div>

      <div class="mt-2">
        <h4>SKILLS</h4>
        <b-row class="mt-2">
          <b-col cols="12" md="6">
            <div class="bg-light text-dark p-4">
              <b-row>
                <b-col>
                  <label for="">Topic: *</label>
                  <b-form-select
                    v-model="temp_skill"
                    :options="skillList"
                    size="sm"
                    class="mt-2"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <label for="">Experience: *</label>
                  <b-form-select
                    v-model="temp_work_exp"
                    :options="experienceList"
                    size="sm"
                    class="mt-2"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" v-if="skillMsg">
                  <div class="mt-4 text-left text-danger">{{ skillMsg }}</div>
                </b-col>
                <b-col cols="12" class="mt-2 text-right">
                  <b-button class="mt-3" @click.prevent="addSkill(temp_skill, temp_work_exp)"
                    >ADD SKILL</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div
              class="bg-light text-dark shadow-sm p-2 mb-2"
              v-for="(skill, index) in form.skills"
              :key="index"
            >
              <b-row>
                <b-col md="3">Topic</b-col>
                <b-col md="7" class="font-weight-bold">{{
                  getTopicDisplay(skill.topic)
                }}</b-col>
                <b-col md="2" class="text-right">
                  <b-button
                    @click="skillRemove(skill.topic)"
                    type="button"
                    variant="outline-danger"
                    size="sm"
                    ><i class="fas fa-trash-alt"></i></b-button
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">Experience</b-col>
                <b-col md="9" class="font-weight-bold">{{
                  getExperienceDisplay(skill.experience)
                }}</b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="mt-4">
        <h4>Work Experience</h4>
        <b-row class="mt-2">
          <b-col cols="12" md="6">
            <div class="bg-light text-dark p-4">
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-form-group
                    label="Institute Name: *"
                    label-class="required"
                    description=""
                    
                  >
                    <b-form-input
                      type="text"
                      placeholder="Institute"
                      v-model="institute_name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="mt-2" cols="12">
                  <b-form-group
                    label="Position: *"
                    label-class="required"
                    description="">
                      <b-form-input
                        type="text"
                        placeholder="Position"
                        v-model="position"
                      ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="mt-2" cols="12">
                  <b-form-group
                    label="Description"
                    label-class="required"
                    description=""
                  >
                    <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                  </b-form-group>
                </b-col>
                <b-col class="mt-2" cols="6">
                  <b-form-group
                    label="Start Time: *"
                    label-class="required"
                    description=""
                  >
                    <b-form-input
                      type="date"
                      v-model="start_time"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="mt-2" cols="6">
                  <b-form-group
                    label="End Time"
                    label-class="required"
                    description=""
                  >
                    <b-form-input type="date" v-model="end_time"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" v-if="instituteMsg || positionMsg || start_timeMsg">
                  <div class="mt-4 text-left text-danger" v-if="instituteMsg">{{ instituteMsg }}</div>
                  <div class="mt-4 text-left text-danger" v-if="positionMsg">{{ positionMsg }}</div>
                  <div class="mt-4 text-left text-danger" v-if="start_timeMsg">{{ start_timeMsg }}</div>
                </b-col>
                <b-col cols="12">
                  <div class="mt-2 text-right">
                    <b-button
                    class="mt-3"
                      @click.prevent="
                        addExperience(
                          institute_name,
                          position,
                          description,
                          start_time,
                          end_time
                        )">
                      ADD EXPERIENCE</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div
              class="bg-light text-dark shadow-sm p-2 mb-2"
              v-for="(work_exp, index) in form.work_exps"
              :key="index"
            >
              <b-row>
                <b-col cols="12" md="3">
                  <b-col cols="12">Institute</b-col>
                </b-col>
                <b-col cols="12" md="7">
                   <b>{{ work_exp.institute_name }}</b>
                </b-col>

                <b-col cols="12" md="2" class="text-right">
                  <b-button
                    @click="experienceRemove(work_exp.institute_name)"
                    type="button"
                    variant="outline-danger"
                    size="sm"
                    ><i class="fas fa-trash-alt"></i></b-button
                  >
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3">
                  <b-col cols="12">Position</b-col>
                </b-col>
                <b-col cols="12" md="9">
                   <b>{{ work_exp.position }}</b>
                </b-col>
              </b-row>

              <b-row v-if="work_exp.description">
                <b-col cols="12" md="3">
                  <b-col cols="12">Description</b-col>
                </b-col>
                <b-col cols="12" md="9">
                   <div v-html="work_exp.description"></div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3">
                  <b-col cols="12">Duration</b-col>
                </b-col>
                <b-col cols="12" md="9">
                   <small>
                        <span v-if="work_exp.start_time"><b>{{ work_exp.start_time }}</b> To</span>
                        <span v-if="work_exp.end_time"><b>{{ work_exp.end_time }}</b></span>
                        <span v-else><b>PRESENT</b></span>
                      </small>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4 mb-3">
          <b-col>
            <div>
              <p class="mb-2">Cover Letter:</p>
              <ckeditor :editor="editor" v-model="form.cover_letter" :config="editorConfig"></ckeditor>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="CV/Resume"
              label-class="required"
              description=""
            >
              <b-form-file v-model="form.resume" plain></b-form-file>
            </b-form-group>
            <!--<h5 v-if="previous_resume">{{previous_resume}}</h5>
            <embed type="file/pdf" :src="previous_resume" width="300" height="200">--->
          </b-col>
        </b-row>
      </div>

      <div class="mt-4 text-right">
        <b-row>
          <b-col class="text-left">
            <b-button type="reset" variant="danger">RESET</b-button>
          </b-col>
          <b-col>
            <b-button style="width:130px;" :disabled="onProgress" type="submit" variant="primary">
              <span v-if="!onProgress">SUBMIT</span>
              <span v-else><b-spinner :class="'spinner'"></b-spinner></span> 
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </b-modal>
</template>

<script>

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {mapActions, mapState} from 'vuex'


export default {
  data() {
    return {
      ...mapState(["candidate"]),
      onProgress: false,
      skillList: null,
      experienceList: null,
      jobs: [],
      previous_resume: null,
      form: {
        id: null,
        name: null,
        candidate_id: null,
        identification_no: null,
        email: null,
        phone_no: null,
        address: null,
        linkedin_url: null,
        github_url: null,
        website_url: null,
        about_self: null,
        resume: null,
        cover_letter: null,
        skills: [],
        work_exps: [],
        job_listing: null,
      },
      show: true,

      temp_skill: null,
      temp_work_exp: null,
      institute_name: null,
      position: null,
      description: null,
      start_time: null,
      end_time: null,
      skillMsg: null,
      instituteMsg: null,
      positionMsg: null,
      start_timeMsg: null,

      editor: ClassicEditor,
      editorConfig:{
        toolbar: {
            items: [
                "bold",
                "italic",
                "BulletedList",
                "NumberedList",
                "undo",
                "redo"
            ]
        }
      }
    };
  },
  created() {
    this.fetchSkillList();
    this.fetchExperienceList();
    this.fetchLength();
    this.fetchJobListing()
  },

  methods: {
    ...mapActions("candidate", ["getSkills", "getExperiences", "getLength", "jobListings", "updateCandidate", "getCandidate"]),

    fetchLength(){
        this.getLength().then((response)=>{
            this.form.candidate_id = parseInt(response)+parseInt(1)
        })
    },

    skillRemove(topicId) {
      var updated_skills = this.form.skills.filter(function (value) {
        return value.topic != topicId;
      });
      this.form.skills = updated_skills;
    },
    experienceRemove(institute) {
      var updated_exp = this.form.work_exps.filter(function (value) {
        return value.institute_name != institute;
      });
      this.form.work_exps = updated_exp;
    },

    getTopicDisplay(topicId) {
      var skill = this.skillList.find((skill) => skill.value == topicId);
      return skill.text;
    },

    getExperienceDisplay(expId) {
      var exp = this.experienceList.find((exp) => exp.value == expId);
      return exp.text;
    },

    fetchSkillList() {
        this.getSkills().then((response)=> {
            this.skillList = response;
        })
    },

    fetchExperienceList() {
        this.getExperiences().then((response)=> {
            this.experienceList = response
        })
    },

    fetchJobListing(){
        this.jobListings().then(response=> {
            response.forEach((job)=>{
                this.jobs.push({
                    value: job.id,
                    text: `${job.title}`
                })
            })
        })
    },

    addSkill(topic, wexp) {
      var found_skill = this.form.skills.find((skill) => skill.topic == topic);
      if (found_skill) {
        this.skillMsg = "Skill already added!";
        this.temp_skill = null;
        this.temp_work_exp = null;
        setTimeout(()=>{
          this.skillMsg=null
        }, 3000)
      } else if ([null, undefined, ""].includes(topic) || [null, undefined, ""].includes(wexp)) {
        this.skillMsg = "Both topic & experience must be filled up";
        this.temp_skill = null;
        this.temp_work_exp = null;
        setTimeout(()=>{
          this.skillMsg=null
        }, 3000)
      } else {
        this.form.skills.push({
          topic: topic,
          experience: wexp,
        });

        this.temp_skill = null;
        this.temp_work_exp = null;
        this.skillMsg = null;
      }
    },

    addExperience(inst,pos, desc, start_time, end_time) {
      if(!inst){
        this.instituteMsg = "Please enter the organization name!"
        setTimeout(()=>{
          this.instituteMsg = null
        }, 3000)
      }else if(!pos){
        this.positionMsg = "Please enter position field!"
        setTimeout(()=>{
          this.positionMsg = null
        }, 3000)
      }else if(!start_time){
        this.start_timeMsg = "Please enter Starting date of your job!"
        setTimeout(()=>{
          this.start_timeMsg = null
        }, 3000)
      }else{
        this.form.work_exps.push({
        institute_name: inst,
        position:pos,
        description: desc,
        start_time: start_time,
        end_time: end_time,
      });

      this.institute_name = null;
      this.position = null;
      this.description = null;
      this.start_time = null;
      this.end_time = null;
      this.instituteMsg = null;
      this.positionMsg = null;
      this.start_timeMsg = null;
      console.log(this.form.work_exps)
      }
    },

    onSubmit() {
      this.onProgress = true
      let formData = new FormData()
      formData.append('id', this.form.id)
      formData.append('name', this.form.name)
      formData.append('identification_no', this.form.identification_no)
      formData.append('email', this.form.email)
      formData.append('phone_no', this.form.phone_no)
      formData.append('address', this.form.address)
      formData.append('linkedin_url', this.form.linkedin_url)

      for (let skill in this.form.skills) {
        if (![undefined, null, ""].includes(this.form.skills[skill].id)) {
            formData.append(`skills[${skill}]id`, this.form.skills[skill].id)
        }
        formData.append(`skills[${skill}]topic`, this.form.skills[skill].topic)
        formData.append(`skills[${skill}]experience`, this.form.skills[skill].experience)
      }

      for (let work_exp in this.form.work_exps) {
        if (![undefined, null, ""].includes(this.form.work_exps[work_exp].id)){
            formData.append(`work_exps[${work_exp}]id`, this.form.work_exps[work_exp].id)
        }
        formData.append(`work_exps[${work_exp}]institute_name`, this.form.work_exps[work_exp].institute_name)
        formData.append(`work_exps[${work_exp}]description`, this.form.work_exps[work_exp].description)
        formData.append(`work_exps[${work_exp}]position`, this.form.work_exps[work_exp].position)
        formData.append(`work_exps[${work_exp}]start_time`, this.form.work_exps[work_exp].start_time)
        formData.append(`work_exps[${work_exp}]end_time`, this.form.work_exps[work_exp].end_time)
      }

      formData.append('github_url',this.form.github_url)
      formData.append('website_url',this.form.website_url)
      formData.append('about_self',this.form.about_self)

      if (this.form.resume) {
        formData.append('resume',this.form.resume)
      }

      formData.append('cover_letter',this.form.cover_letter)
      formData.append('job_listing',this.form.job_listing)
      const data = {
          formData: formData,
          candidate: this.form
      }
      this.updateCandidate(data).then(response=>{
        if(response.status == 200){
            this.$bvModal.hide("modal-update-candidate")
            this.onReset()
            this.onProgress = false
        }
      })

    },

    onShow(){
        this.getCandidate(this.$store.state.candidate.selected_candidate_id)
            .then((response)=> {
                this.form.id = this.$store.state.candidate.selected_candidate_id
                this.form.name = response.name
                this.form.candidate_id = response.candidate_id
                this.form.identification_no = response.identification_no
                this.form.email = response.email
                this.form.phone_no = response.phone_no
                this.form.address = response.address
                this.form.linkedin_url = response.linkedin_url
                this.form.github_url= response.github_url
                this.form.website_url = response.website_url
                this.form.about_self = response.about_self
                this.previous_resume = response.resume
                this.form.cover_letter = response.cover_letter
                this.form.skills = response.skills
                this.form.work_exps = response.work_exps
                this.form.job_listing = response.job_listing
                
            })
    },

    onReset() {
      this.form = {
        name: null,
        candidate_id: null,
        identification_no: null,
        email: null,
        phone_no: null,
        address: null,
        linkedin_url: null,
        github_url: null,
        website_url: null,
        about_self: null,
        resume: null,
        cover_letter: null,
        skills: [],
        work_exps: [],
        job_listing: null
      };
      this.temp_skill = null;
      this.temp_work_exp = null;
      this.job_listing = null;
    },
  },
};
</script>

<style scoped>
  .spinner {
    height: 1.2rem;
    width: 1.2rem;
  }
</style>