var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "section-intro",
        staticStyle: {
          background: "url('/static/img/pattern-randomized.svg')",
        },
        attrs: { id: "intro" },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-7 mt-5" }, [
              _c(
                "div",
                {
                  staticClass: "align-middle animate__animated animate__fadeIn",
                },
                [
                  _c(
                    "h2",
                    { staticStyle: { color: "#2a424e", "font-size": "35px" } },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("Providing Tech Solutions and Training"))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "mt-3", staticStyle: { color: "gray" } },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "Empowering Businesses with Cloud and Mobile App Solutions. We are your strategic partner in crafting cutting-edge technology solutions that drive efficiency, innovation, and growth across industries."
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4 animate__animated animate__fadeInRight" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-getintouch page-scroll",
                      attrs: { href: "#contact" },
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.$t("Contact Us")))])]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("modal-intro-vue"),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-md-5 text-center" }, [
      _c("img", {
        attrs: {
          src: "/static/img/illustration.svg",
          width: "800",
          height: "100%",
          alt: "SyncDots",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }