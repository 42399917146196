<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <b-table 
                    small 
                    striped
                    bordered
                    hover 
                    :items="service.service_list" 
                    :fields="fields" show-empty
                    @row-hovered="rowHovered"
                    @row-unhovered="rowUnHovered"
                >
                    <template v-slot:cell(brief)=row>
                        <span v-html="row.item.brief">{{row.item.brief}}</span>
                    </template>

                    <template v-slot:cell(logo)=row>
                        <b-link :href="row.item.logo" target="_blank">Show</b-link>
                    </template>

                    <template v-slot:cell(actions)=row>
                        <div v-show="selected_row == row.item.id" class="m-0 p-0">
                            <b-button size="sm" variant="outline-info" v-b-modal.modal-view-service @click="onView(row.item.id)">
                                <i class="fas fa-eye"></i>
                            </b-button>
                            <b-button size="sm" variant="outline-info" v-b-modal.modal-update-service @click="onUpdate(row.item.id)">
                                <i class="fas fa-pen-alt"></i>
                            </b-button>
                            <b-button variant="outline-danger" size="sm" @click="onDelete(row.item.id)">
                                <i class="fas fa-trash-alt"></i>
                            </b-button>
                        </div>
                    </template>
                </b-table>
                <b-pagination-nav
                    :link-gen="linkGen"
                    :number-of-pages="totalPage"
                    @change="fetchServices"
                    use-router
                    class="float-right">
                </b-pagination-nav>
            </b-col>
        </b-row>



    </div>
</template>


<script>


import { mapState, mapActions, mapMutations } from 'vuex'
export default {  
    data(){
        return {
            selected_row: null,
            perPage: 10,
            fields: [
                {
                    key: 'title',
                    sortable: true,
                    label: "Title",
                    class: "text-left w-15"
                },

                {
                    key: 'brief',
                    sortable: true,
                    label: "Brief",
                    class: "text-left w-30"
                },

                {
                    key: 'logo',
                    sortable: true,
                    label: "Logo",
                    class: "text-center w-15"
                },

                {
                    key: 'actions',
                    sortable: true,
                    label: "Actions",
                    class: "text-center w-15"
                }
            ]
        }
    },

    computed: {
        ...mapState(["service"]),
        page() {
            return parseInt(this.$route.query.page) || 1;
        },
    
        totalPage() {
            if (this.service.total_service % this.perPage !== 0) {
                return this.service.total_service / this.perPage + 1;
            }
            return this.service.total_service / this.perPage;
        },
    },
    methods: {
        ...mapActions("service", ["getServices", "deleteService"]),
        ...mapMutations("service", ["SELECT_SERVICE_ID"]),

        rowHovered (record, index) {
            this.selected_row = record.id
        },

        rowUnHovered () {
            this.selected_row = null
        },

        onView(id){
            this.SELECT_SERVICE_ID(id)
        },

        onUpdate(id){
            console.log(id)
            this.SELECT_SERVICE_ID(id)
        },

        onDelete(id){
            this.$bvModal.msgBoxConfirm('Confirm delete Service?', {
            title: 'DELETE Service',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'CONFIRM',
            cancelTitle: 'CANCEL',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
          .then(value => {
            if (value) {
              this.deleteService(id)
            }
          })
          .catch(err => {
            // An error occurred
          })
        },

        linkGen(pageNum) {
            return pageNum === 1 ? "?" : `?page=${pageNum}`;
        },

        fetchServices(){
            this.getServices()
        }
    },

    created(){
        this.fetchServices()
    },



}
</script>