var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-view-product",
        title: "Product Detail",
        size: "xl",
        centered: "",
        "hide-footer": "",
        scrollable: true,
        "no-enforce-focus": "",
      },
      on: { show: _vm.onShow },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-img",
                _vm._b(
                  {
                    staticClass: "mt-3",
                    attrs: { src: _vm.logo, thumbnail: "" },
                  },
                  "b-img",
                  _vm.mainProps,
                  false
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-col", [
            _c("h4", [_vm._v("Information")]),
            _vm._v(" "),
            _c("p", { staticClass: "mt-3" }, [
              _c("b", [_vm._v("Title: ")]),
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-3",
                domProps: { innerHTML: _vm._s(_vm.description) },
              },
              [
                _c("b", [_vm._v("Description: ")]),
                _c("br"),
                _vm._v(_vm._s(_vm.description)),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }