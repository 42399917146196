<template>
    <b-modal 
    id="modal-view-article" 
    title="Article Detail"
    @show="onShow"
    size= "xl"
    centered
    hide-footer
    :scrollable="true"
    no-enforce-focus>
        <b-row>
            <b-col>
                <h4 class="mb-2">{{title}}</h4>
                <small>{{date}}</small>
                <p class="mt-3" v-html="description"><b>Description: </b></p>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import {mapActions, mapState} from "vuex"
export default{
    data(){
        return {
            title: null,
            date: null,
            description: null,
        }
    },
    computed: {
        ...mapState(["blog"])
    },
    methods:{
        ...mapActions("blog", ["getArticle"]),

        onShow(){
            this.getArticle(this.blog.selected_article_slug).then((response)=>{
                console.log(response)
                this.title = response.title
                this.date = response.date
                this.description = response.description
            })
        }
    }
}
</script>