var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: {
          striped: "",
          bordered: "",
          hover: "",
          small: "",
          items: _vm.blog.article_list,
          fields: _vm.fields,
          "show-empty": "",
        },
        on: {
          "row-hovered": _vm.rowHovered,
          "row-unhovered": _vm.rowUnHovered,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(title)",
            fn: function (row) {
              return [
                _c("span", { staticClass: "mr-1" }, [
                  _vm._v("\n        " + _vm._s(row.item.title) + "\n      "),
                ]),
              ]
            },
          },
          {
            key: "cell(tags_display)",
            fn: function (row) {
              return _vm._l(row.item.tags_display, function (tag) {
                return _c("span", { key: tag.id, staticClass: "mr-1" }, [
                  _vm._v("\n        " + _vm._s(tag.slug) + ",\n      "),
                ])
              })
            },
          },
          {
            key: "cell(actions)",
            fn: function (row) {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selected_row == row.item.slug,
                        expression: "selected_row == row.item.slug",
                      },
                    ],
                  },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modal-view-article",
                            modifiers: { "modal-view-article": true },
                          },
                        ],
                        attrs: { size: "sm", variant: "outline-info" },
                        on: {
                          click: function ($event) {
                            return _vm.onView(row.item.slug)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-eye" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-danger", size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.onDelete(row.item.slug)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-trash-alt" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modal-update-article",
                            modifiers: { "modal-update-article": true },
                          },
                        ],
                        attrs: { size: "sm", variant: "outline-info" },
                        on: {
                          click: function ($event) {
                            return _vm.onUpdate(row.item.slug)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-pen-alt" })]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-end" },
        [
          _c("b-pagination-nav", {
            attrs: {
              "link-gen": _vm.linkGen,
              "number-of-pages": _vm.totalPage,
              "use-router": "",
            },
            on: { change: _vm.fetchArticle },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("article-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }