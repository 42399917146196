<template>
  <section id="products" class="products-area" style="position: relative">
    <Nav></Nav>
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <div class="section-title">
            <h4 class="title">{{ $t("Products / Portfolio") }}</h4>
          </div>
          <!-- section title -->
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="section-title text-right" style="margin-right: 1.2rem">
            <b-link :to="{ path: '/product/' }" class="w-30">
              <b-button class="see-all-button w-100">{{  $t("See All") }}</b-button>
            </b-link>
          </div>
          <!-- section title -->
        </div>
      </div>

      <div v-if="allProducts.length == 0">
        <b-row>
          <b-col
              cols="12"
              md="2"
              v-for="index in 6"
              :key="-index"
            >
              <content-loader viewBox="0 0 200 400" height="400">
                <rect x="0" y="0" rx="5" ry="5" width="500" height="500" />
              </content-loader>
          </b-col>
        </b-row>
      </div>

      <div v-if="allProducts.length > 0">
        <b-row>
          <b-col
            cols="12"
            md="2"
            v-for="product in allProducts"
            :key="product.id"
            @click.prevent="product_details(product.slug)"
          >
            <img
              :src="product.logo"
              :alt="product.title"
              class="product-card mb-4"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import Nav from "../../components/Nav.vue";
import { mapState, mapActions } from "vuex";
import ProductCard from "./ProductCard.vue";
import carousel from "vue-owl-carousel";
import { ContentLoader } from "vue-content-loader";

export default {
  data() {
    return {
      perPage: 10,
      allProducts: [],
    };
  },
  components: {
    Nav,
    ProductCard,
    carousel,
    ContentLoader
  },
  computed: {
    ...mapState(["product"]),
  },
  methods: {
    ...mapActions("product", ["getProducts"]),
    getValues() {
      this.product.product_list.forEach((product) => {
        this.allProducts.push(product);
      });
    },
    fetchProducts() {
      this.getProducts({
        perPage: 3,
        page: 1,
      });
    },
    product_details(slug) {
      this.$router.push({
        name: "product-detail",
        params: {
          slug: slug,
        },
      });
    },
  },
  created() {
    this.fetchProducts();
    setTimeout(() => {
      this.getValues();
    }, 500);
  },
};
</script>

<style scoped>
.see-all-button {
  font-size: 16px;
  border-radius: 25px;
  background-image: linear-gradient(to right, #DE621B, rgb(248, 169, 123));
  /* border-bottom: 3.5px solid #bb4500;
  border-right: 2px solid #bb4500; */
  color: white;
}

.see-all-button:hover {
  background-image: linear-gradient(to right, #DE621B, rgb(248, 169, 123));
  border: 1px solid #c04c09;
  color: white;
}

.custom-margin {
  margin: 0 0 0 14px;
}
.product-carousal-previous {
  position: absolute;
  top: 14rem;
  margin-left: -4rem;
  cursor: pointer;
  font-size: 1.5rem;
}
.product-carousal-previous:hover {
  color: #f27932;
}
.product-carousal-next {
  position: absolute;
  top: 14rem;
  margin-left: 70rem;
  cursor: pointer;
  font-size: 1.5rem;
}
.product-carousal-next:hover {
  color: #f27932;
}
.card-div {
  background-size: cover;
  width: 20rem;
  height: auto;
  border-radius: 20px;
  font-size: 25px;
  text-align: center;
  padding-top: 40%;
  transition: all 0.3s ease-in-out;
  margin: 0.5rem 0;
}
.card-div:hover {
  transform: scale(1.05);
}
.title-css {
  color: white;
  transition: all 0.3s ease-in-out;
}
.card-div:hover .title-css {
  transform: scale(1.05);
}
.product-card {
  background-size: cover;
  border-radius: 10px;
  font-size: 25px;
  transition: all 0.3s ease-in-out;
}
.product-card:hover {
  transform: scale(1.04);
  cursor: pointer;
}
</style>