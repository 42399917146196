var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container mt-4" },
    [
      _c("Nav"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm._l(2, function (index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.jobList.length == 0,
                    expression: "jobList.length == 0",
                  },
                ],
                key: -index,
                staticClass: "col-12 col-md-6",
              },
              [
                _c(
                  "content-loader",
                  { attrs: { viewBox: "0 0 400 600", height: "220" } },
                  [
                    _c("rect", {
                      attrs: {
                        x: "0",
                        y: "0",
                        rx: "10",
                        ry: "10",
                        width: "400",
                        height: "220",
                      },
                    }),
                  ]
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.jobList, function (job) {
            return _c("div", { key: job.id, staticClass: "col-12 col-md-6" }, [
              _c("div", { staticClass: "shadow-sm rounded p-3" }, [
                _c("h4", { staticStyle: { color: "#f27932" } }, [
                  _vm._v(_vm._s(job.title)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _c("i", {
                    staticClass: "fas fa-book",
                    staticStyle: { color: "#f27932" },
                  }),
                  _vm._v(" Skills Required "),
                  _c("br"),
                  _vm._v(" "),
                  _c("b", [_vm._v(_vm._s(job.required_skills))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-2" }, [
                  _c("i", {
                    staticClass: "fas fa-user-tie",
                    staticStyle: { color: "#f27932" },
                  }),
                  _vm._v(" Experience Required "),
                  _c("br"),
                  _vm._v(" "),
                  _c("b", [_vm._v(_vm._s(job.experience))]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-4 text-right" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modal-create-candidate",
                            modifiers: { "modal-create-candidate": true },
                          },
                        ],
                        attrs: { variant: "outline-primary", size: "md" },
                        on: {
                          click: function ($event) {
                            return _vm.assignId(job.id)
                          },
                        },
                      },
                      [_vm._v("APPLY")]
                    ),
                  ],
                  1
                ),
              ]),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("CreateCandidate", { attrs: { jobId: _vm.jobId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }