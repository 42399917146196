<template>
  <div>
    <div class="testimonials">

        <div>
          <div class="card shadow-sm mr-4 mb-4 text-center">
            <img
              class="card-img-top"
              :src="testimonial.profile_photo"
              alt=""
            />
            <div class="p-2">
              <h5 style="color: #f27932;">
                {{testimonial.name}} <br />
                <span> {{testimonial.company_name}} </span> <br>
                <span> <small>{{testimonial.designation}}</small> </span>
              </h5>
              <div class="mt-2"></div>
              <p class="text-left" style="font-size:13px;color:gray">
                {{testimonial.description}}
              </p>
            </div>
          </div>
        </div>
      
    </div>
  </div>
</template>

<script>

export default {
  props: ["testimonial"],
};
</script>

<style scoped>

.testimonials {
  position: relative;
  margin-top: 10px;
}

.testimonials .card {
  background: #fff;
  box-shadow: 0 8px 30px -7px #ffe6d7;
  padding: 0 10px;
  border-radius: 20px;
  border: 0;
  min-height: 25rem;
}

.testimonials .card .card-img-top {
  max-width: 120px;
  border-radius: 50%;
  margin: 15px auto 0;
  box-shadow: 0 8px 20px -4px #ffe6d7;
  width: 120px;
  height: 120px;
}

.testimonials .card h5 {
  color: #F27932;
  font-size: 21px;
  line-height: 1.3;
}

.testimonials .card h5 span {
  font-size: 18px;
  color: #666666;
}

.testimonials .card p {
  font-size: 18px;
  color: #555;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .testimonials {
    margin-top: 20px;
  }
}

</style>