<template>
    <div>
        <Nav></Nav>
        <b-container>
            <b-row class="mt-4" v-show="!title">
                <b-col md="4" class="">
                    <content-loader viewBox="0 0 200 400" height="400">
                        <rect x="0" y="0" rx="5" ry="5" width="400" height="400" />
                    </content-loader>
                </b-col>
                <b-col md="8" class="">
                    <content-loader viewBox="0 0 200 400" height="400">
                        <rect x="0" y="0" rx="5" ry="5" width="500" height="30" />
                        <rect x="0" y="35" rx="5" ry="5" width="500" height="300" />
                    </content-loader>
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col md="4" cols="12" class="">
                    <b-img class="w-100 zoom rounded" v-bind="mainProps" :src="logo" ></b-img>
                </b-col>
                <b-col md="8" cols="12" class="">
                    <h4 class="title">{{title}}</h4>
                    <p v-html="description" class="mt-3"></p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ContentLoader } from "vue-content-loader";
import Nav from '../Nav.vue'

export default {
    props: ['slug'],
    components: {
        ContentLoader,
        Nav
    },
    data(){
        return{
            title: null,
            description: null,
            logo: null,
            mainProps: {
                left: true,
                width: 200
            }
        }
    },
    methods: {
        ...mapActions("product", ["getProductBySlug"])
    },

    created(){
        this.getProductBySlug(this.slug).then((response)=>{
            this.title = response.title
            this.description = response.description
            this.logo = response.logo
        })
    }
}
</script>

<style scoped>
.zoom {
    transition: all 0.2s ease-in-out;
}
.zoom:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.title {
    color: #f27932;
}
</style>