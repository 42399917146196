
import ServiceBlog from "../../service/ServiceBlog.js";

export const namespaced = true



export const state = {
    product: null,
    product_list: [],
    total_product: null,
    selected_product_id: null
}

export const mutations = {
    ADD_PRODUCTS(state, response_data){
        state.product_list = response_data.results,
        state.total_product = response_data.count
    },

    SELECT_PRODUCT_ID(state, id){
        state.selected_product_id = id
        
    },

    SELECT_PRODUCT(state, product){
        state.product = product
    },

    REMOVE_PRODUCT(state, id){
        state.product_list = state.product_list.filter(product =>{
            return product.id != id
        })
    },

    ADD_PRODUCT(state, product){
        state.product_list.push(product)
    },

    UPDATE_PRODUCT(state, id){
        state.product_list.filter(product =>{
            if(product.id == id){
                console.log('updated')
            }
        })
    },

    PRODUCT_BY_SLUG(state){
        console.log('Got product by slug')
    }
}

export const actions = {
    getProducts({commit},{perPage,page}){
        ServiceBlog.getProducts(perPage,page).then((response)=>{
            if(response.status == 200){
                commit('ADD_PRODUCTS', response.data)
            }
        })
    },

    getProduct({commit}, id){
        return ServiceBlog.getProduct(id).then((response)=>{
            if(response.status == 200){
                commit('SELECT_PRODUCT', response.data)
                return response.data
            }
        })
    },

    deleteProduct({commit}, id){
        return ServiceBlog.deleteProduct(id).then((response)=>{
            if(response.status == 204){
                Vue.toasted.show(`Successfully deleted Product`, { className: 'bg-danger' })
                commit('REMOVE_PRODUCT', id)
                return response.status
            }
        })
    },

    createProduct({commit}, data){
        return ServiceBlog.createProduct(data.formData).then((response)=>{
            if(response.status == 201){
                Vue.toasted.show(`Successfully created Service`, { className: 'bg-success' })
                commit('ADD_PRODUCT', data.service)
                return response.status
            }
        })
    },

    updateProduct({commit}, data){
        return ServiceBlog.updateProduct(data.formData, data.id).then((response)=>{
            if(response.status == 200){
                Vue.toasted.show(`Successfully updated Product`, { className: 'bg-success' })
                commit('UPDATE_PRODUCT', data.id)
                return response.status
            }
        })
    },

    getProductBySlug({commit}, slug){
        return ServiceBlog.getProductBySlug(slug).then((response)=>{
            if(response.status == 200){
                commit('PRODUCT_BY_SLUG')
                return response.data
            }
        })
    }
}