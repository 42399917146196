<template>
  <div>
    <b-modal 
        id="intro-modal"
        centered
        hide-footer
        size="xl"
        header-class="intro-modal"
        content-class="intro-modal"
    >
        <div class="text-center">
            <iframe width="1100" height="618" src="https://www.youtube.com/embed/22iGLntzgmg?autoplay=1&start=2" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
             allowfullscreen></iframe>
        </div>
    </b-modal>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>