var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("div", { staticClass: "container" }, [
      _c("h4", { staticClass: "title" }, [_vm._v("Message from Founder")]),
      _vm._v(" "),
      _c("div", { staticClass: "row animate__animated animate__fadeIn" }, [
        _c("div", { staticClass: "col-12 col-md-3 mt-3" }, [
          _c("img", {
            staticClass: "img-founder shadow-sm",
            attrs: { src: _vm.imageFounder, alt: "Ohid" },
          }),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "msg-section col-12 col-md-9 text-center d-flex align-items-center mt-3",
      },
      [
        _c("p", { staticClass: "msg-founder" }, [
          _vm._v(
            '\n                    "As a dreamer & learner, I had always dreamed\n                      of building a company which would help the earth to be\n                      a better place, even if by an inch. In SyncDots, we try\n                      take this vision as a sacred goal in our works. Everything\n                      we do here MUST serve for the benefits of the mother\n                      earth directly or indirectly. For this vision, technologies\n                      & businesses are our tools to make it alive." '
          ),
          _c("br"),
          _vm._v(" "),
          _c("small", [_c("b", [_vm._v("- Ohidul Alam, CTO & Founder")])]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }