var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Nav"),
      _vm._v(" "),
      _c("intro-content"),
      _vm._v(" "),
      _c("service-content"),
      _vm._v(" "),
      _c("product-content"),
      _vm._v(" "),
      _c("section", { staticClass: "contact-area", attrs: { id: "contact" } }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "section-title" }, [
                  _c("h4", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("Contact Us"))),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "b-row",
              { staticClass: "mt-4" },
              [
                _c(
                  "b-col",
                  { attrs: { md: "3", cols: "12" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "text-left", attrs: { md: "12" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "fa-stack fa-2x mb-2",
                                staticStyle: { color: "#f27932" },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-circle fa-stack-2x",
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass:
                                    "fas fa-phone fa-stack-1x fa-inverse",
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {},
                          [
                            _c(
                              "b-col",
                              {
                                staticStyle: { color: "#f27932" },
                                attrs: { md: "12" },
                              },
                              [_vm._v("Call Us")]
                            ),
                            _vm._v(" "),
                            _c("b-col", { attrs: { md: "12" } }, [
                              _vm._v("+62-8218-0000-247"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { md: "6", cols: "12" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "text-left", attrs: { md: "12" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "fa-stack fa-2x mb-2",
                                staticStyle: { color: "#f27932" },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-circle fa-stack-2x",
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass:
                                    "fas fa-map-marker-alt fa-stack-1x fa-inverse",
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          {
                            staticStyle: { color: "#f27932" },
                            attrs: { md: "12" },
                          },
                          [_vm._v("Indonesia Office")]
                        ),
                        _vm._v(" "),
                        _c("b-col", { attrs: { md: "12" } }, [
                          _vm._v(
                            "\n              Ruko Crystal no 2, Jl. Gading Golf Boulevard, Gading Serpong, Kec. Klp. Dua, Kabupaten Tangerang, Banten 15810\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { md: "3", cols: "12" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "fa-stack fa-2x mb-2",
                              staticStyle: { color: "#f27932" },
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-circle fa-stack-2x",
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "far fa-envelope fa-stack-1x fa-inverse",
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("b-col", { attrs: { md: "12" } }, [
                          _vm._v("contact@syncdots.id"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }