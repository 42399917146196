<template>
    <div class="container mt-4">
      <b-row>
        <b-col cols="12" md="9">
          <b-row v-if="caseStudy">
            <b-col cols="12">
              <h2 style="color:#f27932;">{{caseStudy.title}}</h2>
            </b-col>
            <b-col cols="12">
              <span class="mt-2">
                <small>{{caseStudy.modified}}</small>
              </span>
            </b-col>
            <b-col cols="12">
              <small>Views 15K</small>
            </b-col>
            <b-col cols="12" class="text-center">
              <b-img class="m-4" style="height:20rem" :src="caseStudy.image"></b-img>
            </b-col>
            <b-col cols="12">
              <div class="text-justify">{{caseStudy.description}}</div>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="1">

        </b-col>
        <b-col md="2">
          <b-row class="custom-card">
            <b-col md="12" class="text-center mt-2">Our Services</b-col>
            <b-col md="12" style="height:25rem; overflow-y:auto">
              <div v-for="service,index in service.service_list" :key="index">
                <a href="#" @click.prevent="service_details(service.slug)">
                  <b-img class="sidebar-img" :src="service.logo"></b-img>
                </a>
              </div>
            </b-col>
          </b-row>
        </b-col>

      </b-row>

        
        
            <!-- <div class="col-lg-6">
            <div class="section-title text-right">
                <b-link :to="{ path: '' }" class="w-30">
                    <b-button class="see-all-button w-100">
                        SEE ALL
                    </b-button>
                </b-link>
            </div>
            </div> -->



    <!-- <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="totalPage"
      @change="fetchServices"
      use-router
      class="float-right mt-2"
    >
    </b-pagination-nav> -->
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ['slug'],
  data(){
    return {
      caseStudy: null
    }
  },
  computed: {
    ...mapState(["service"]),
  },
  methods: {
    ...mapActions("casestudy", ["getCaseStudyBySlug"]),
    ...mapActions("service", ["getServices"]),
    fetchServices() {
      this.getServices({
        perPage: this.perPage,
        page: this.$route.query.page || 1,
      });
    },
    service_details(slug) {
      this.$router.push({
        name: "service-detail",
        params: {
          slug: slug,
        },
      });
    },
  },
  created(){
    this.fetchServices();
    this.getCaseStudyBySlug(this.slug).then((response)=>{
        this.caseStudy = response
        // this.title = response.title
        // this.description = response.description
        // this.image = response.image
    })
  }
};
</script>

<style>
.custom-card {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  padding-bottom: 1rem;
}
.sidebar-img {
  border-radius: 5px;
  margin: 0.5rem 0;
  width: 10rem;
  height: 10rem;
  overflow-y: auto;
}
.custom-left-margin {
  margin: 0 0 0 14px;
}
.custom-float-left { 
    animation-name: custom-float-left;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}
@keyframes custom-float-left {
    0% { transform: translate(0rem,  10rem); }
    100%   { transform: translate(0rem, 0rem); }   
}

</style>