var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container mt-4 text-justify" },
    [
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c("b-col", { staticClass: "mb-1", attrs: { md: "12" } }, [
            _c("h4", [_vm._v("CASE STUDIES")]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            _vm._l(_vm.casestudy.case_study_list, function (case_study, index) {
              return _c(
                "div",
                { key: index },
                [
                  index < 2
                    ? _c(
                        "b-row",
                        {
                          staticClass: "latest-div",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.case_study_details(case_study.slug)
                            },
                          },
                        },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "3" } },
                            [
                              _c("b-img", {
                                staticClass: "latest-img-css",
                                attrs: { src: case_study.image },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("b-col", { attrs: { md: "9" } }, [
                            _c("h3", [_vm._v(_vm._s(case_study.title))]),
                            _vm._v(" "),
                            _c("small", [_vm._v("DATE: 15th January 2022")]),
                            _c("br"),
                            _vm._v(" "),
                            _c("small", [_vm._v("Views: 15K")]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }