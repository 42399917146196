var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-view-article",
        title: "Article Detail",
        size: "xl",
        centered: "",
        "hide-footer": "",
        scrollable: true,
        "no-enforce-focus": "",
      },
      on: { show: _vm.onShow },
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("h4", { staticClass: "mb-2" }, [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c("small", [_vm._v(_vm._s(_vm.date))]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-3",
                domProps: { innerHTML: _vm._s(_vm.description) },
              },
              [_c("b", [_vm._v("Description: ")])]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }