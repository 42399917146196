var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Nav"),
      _vm._v(" "),
      _c("b-container", [
        _c("h3", { staticClass: "mt-3" }, [_vm._v(_vm._s(_vm.article.title))]),
        _vm._v(" "),
        _c("div", [
          _c("small", [
            _c("span", { staticClass: "mr-2" }, [
              _c("i", { staticClass: "fas fa-calendar-alt" }),
              _vm._v(" " + _vm._s(_vm.article.date)),
            ]),
            _vm._v(" "),
            _c("span", [
              _c("i", { staticClass: "fas fa-eye" }),
              _vm._v(" " + _vm._s(_vm.article.view_count)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("span", {
          staticClass: "mt-3",
          domProps: { innerHTML: _vm._s(_vm.article.description) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }