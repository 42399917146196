
import ServiceBlog from "../../service/ServiceBlog.js";

export const namespaced = true



export const state = {
    testimonial_list: []
}

export const mutations = {
    ADD_TESTIMONIALS(state, testimonials){
        state.testimonial_list = testimonials
    }
}

export const actions = {
    getTestimonials({commit}){
        ServiceBlog.getTestimonials().then((response)=>{
            if(response.status == 200){
                console.log(response.data.results)
                commit('ADD_TESTIMONIALS', response.data.results)
            }
        })
    }
}