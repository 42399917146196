<template>
    <div class="container">
        <Nav></Nav>

        <div class="mt-3" v-for="article in articles" :key="article.title">
            <ArticleCard :article="article"  />
        </div>

        <div class="d-flex justify-content-end mt-4">
            <b-pagination-nav
                :link-gen="linkGen"
                :number-of-pages="totalPage"
                @change="fetchArticle"
                use-router>
            </b-pagination-nav>
        </div>
    </div>
</template>

<script>
import Nav from "../../Nav.vue";
import ArticleCard from './ArticleCard.vue'
import { mapActions, mapState } from 'vuex'
export default {
    components: {
        Nav,
        ArticleCard
    },
    data(){
        return {
            perPage: 15,
            articles: null
        }
    },

    computed: {
        ...mapState(["blog"]),

        page() {
          return parseInt(this.$route.query.page) || 1;
        },
    
        totalPage() {
          if (this.blog.total_article % this.perPage !== 0) {
            return this.blog.total_article / this.perPage + 1;
            }
          return this.blog.total_article / this.perPage;
        },
    },

    methods: {
        ...mapActions("blog", ["getAllArticles", "getArticles"]),

        fetchArticle () {
            this.getArticles({
            perPage: this.perPage,
            page: this.$route.query.page || 1,
            })
            .then((response)=>{
            this.articles = response
        });
        },

        linkGen(pageNum) {
            return pageNum === 1 ? "?" : `?page=${pageNum}`;
        },
    },
    created(){
        this.fetchArticle()
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

a:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


</style>