import Vue from "vue";
import Select2 from "v-select2-component";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Toasted from "vue-toasted";
import i18n from "../i18n.js";
import * as filters from "../filters.js";
import * as animated from 'animate.css';
import store from "./store";
import router from "./router";
import CKEditor from '@ckeditor/ckeditor5-vue';
import IntroContent from './../components/web/IntroContent.vue'
import ServiceContent from './../components/web/ServiceContent.vue'
import ProductContent from './../components/web/ProductContent.vue'
import MessageFromFounder from './../components/web/team/MessageFromFounder.vue'
import OurTeam from './../components/web/team/OurTeam.vue'
import JobListing from './../components/web/career/JobListing.vue'
import PageHome from '../components/web/PageHome.vue'
import Nav from '../components/Nav.vue'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CKEditor)

Vue.component("Select2", Select2);

window.Vue = Vue;

Vue.use(Toasted, {
  class: "rounded",
  position: "bottom-left",
  fitToScreen: true,
  theme: "toasted-primary",
  duration: 4000
});

const app = new Vue({
  el: "#app",
  i18n,
  router,
  store,
  components: {
    Nav,
    IntroContent,
    ServiceContent,
    ProductContent,
    MessageFromFounder,
    OurTeam,
    JobListing,
    PageHome
  },
});
