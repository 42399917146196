<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <b-table 
                small 
                striped 
                hover 
                bordered
                :items="product.product_list" 
                :fields="fields" 
                show-empty
                @row-hovered="rowHovered"
                @row-unhovered="rowUnHovered">
                    <template v-slot:cell(brief)=row>
                        <span v-html="row.item.brief">{{row.item.brief}}</span>
                    </template>

                    <template v-slot:cell(logo)=row>
                        <b-link :href="row.item.logo" target="_blank">Show</b-link>
                    </template>

                    <template v-slot:cell(actions)=row>
                        <div v-show="selected_row === row.item.id">
                            <b-button size="sm" variant="outline-info" v-b-modal.modal-view-product @click="onView(row.item.id)"><i class="fas fa-eye"></i></b-button>
                            <b-button size="sm" variant="outline-info" v-b-modal.modal-update-product @click="onUpdate(row.item.id)"><i class="fas fa-pen-alt"></i></b-button>
                            <b-button variant="outline-danger" size="sm" @click="onDelete(row.item.id)"><i class="fas fa-trash-alt"></i></b-button>
                        </div>

                    </template>
                </b-table>
                <b-pagination-nav
                    :link-gen="linkGen"
                    :number-of-pages="totalPage"
                    @change="fetchProducts"
                    use-router
                    class="float-right">
                </b-pagination-nav>
            </b-col>
        </b-row>



    </div>
</template>


<script>


import { mapState, mapActions, mapMutations } from 'vuex'
export default {  
    data(){
        return {
            selected_row: null,
            perPage: 10,
            fields: [
                {
                    key: 'title',
                    sortable: true,
                    label: "Title",
                    class: "text-left w-15"
                },

                {
                    key: 'brief',
                    sortable: true,
                    label: "Brief",
                    class: "text-left w-30"
                },

                {
                    key: 'logo',
                    sortable: true,
                    label: "Logo",
                    class: "text-center w-15"
                },

                {
                    key: 'actions',
                    sortable: true,
                    label: "Actions",
                    class: "text-center w-15"
                }
            ]
        }
    },

    computed: {
        ...mapState(["product"]),
        page() {
            return parseInt(this.$route.query.page) || 1;
        },
    
        totalPage() {
            if (this.product.total_product % this.perPage !== 0) {
                return this.product.total_product / this.perPage + 1;
            }
            return this.product.total_product / this.perPage;
        },
    },
    methods: {
        ...mapActions("product", ["getProducts", "deleteProduct"]),
        ...mapMutations("product", ["SELECT_PRODUCT_ID"]),

        rowHovered (record, index) {
            this.selected_row = record.id
        },

        rowUnHovered () {
            this.selected_row = null
        },

        onView(id){
            console.log(id)
            this.SELECT_PRODUCT_ID(id)
        },

        onUpdate(id){
            this.SELECT_PRODUCT_ID(id)
        },

        onDelete(id){
            this.$bvModal.msgBoxConfirm('Confirm delete Product?', {
            title: 'DELETE Product',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'CONFIRM',
            cancelTitle: 'CANCEL',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
          .then(value => {
            if (value) {
              this.deleteProduct(id)
            }
          })
          .catch(err => {
            // An error occurred
          })
        },

        linkGen(pageNum) {
            return pageNum === 1 ? "?" : `?page=${pageNum}`;
        },

        fetchProducts(){
            this.getProducts()
        }
    },

    created(){
        this.fetchProducts()
    },



}
</script>