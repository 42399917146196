import Vue from 'vue'
import Router from 'vue-router'
import PageHome from '../../components/web/PageHome.vue'
import JobListing from '../../components/web/career/JobListing.vue'
import OurTeam from '../../components/web/team/OurTeam.vue'

import Blog from '../../components/web/blog/Blog.vue'
import ArticleDetail from '../../components/web/blog/ArticleDetail.vue'
import ServiceDetail from '../../components/web/ServiceDetail.vue'
import ProductDetail from '../../components/web/ProductDetail.vue'

import Product from '../../pages/web/Product.vue'
import News from '../../pages/web/News.vue'
import NewsDetail from '../../components/web/news/NewsDetail.vue'
import CaseStudy from '../../pages/web/CaseStudy.vue'
import CaseStudyDetail from '../../components/web/casestudy/CaseStudyDetail.vue'

import LoginPage from '../../components/login/LoginPage.vue'

import Dashboard from '../../components/syncpanel/Dashboard.vue'
import BlogManagement from '../../components/syncpanel/blog/BlogManagement.vue'
import CandidateManagement from '../../components/syncpanel/candidate/CandidateManagement.vue'
import ServiceManagement from '../../components/syncpanel/service/ServiceManagement.vue'
import ProductManagement from '../../components/syncpanel/product/ProductManagement.vue'


Vue.use(Router)

const routes = [
  { path: '/', name: 'home', component: PageHome },
  { path: '/career/', name: 'career', component: JobListing },
  { path: '/team/', name: 'team', component: OurTeam },
  { path: '/login/', name: 'login', component: LoginPage },

  { path: '/blog/', name: 'blog', component: Blog},
  { path: '/blog/:year/:month/:day/:slug', name: 'article-detail', component: ArticleDetail, props: true },

  { path: '/service/:slug', name: 'service-detail', component: ServiceDetail, props: true},
  { path: '/product/:slug', name: 'product-detail', component: ProductDetail, props: true},
  { path: '/product/', name: 'product', component: Product},
  { path: '/news/', name: 'news', component: News},
  // { path: '/news/:slug', name: 'news-detail', component: NewsDetail, props: true},
  { path: '/news/:slug', name: 'news-detail', component: NewsDetail, props: true},
  { path: '/case-study/', name: 'case-study', component: CaseStudy},
  { path: '/case-study/:slug', name: 'case-study-detail', component: CaseStudyDetail, props: true},

  { path: '/syncpanel/', name: 'dashboard', component: Dashboard },
  { path: '/syncpanel/blog/', name: 'syncpanel-blog', component: BlogManagement },
  { path: '/syncpanel/candidate/', name: 'candidate', component: CandidateManagement },
  { path: '/syncpanel/service/', name: 'service', component: ServiceManagement },
  { path: '/syncpanel/product/', name: 'product', component: ProductManagement }

]

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
      return {x: 0, y: 0}
  }
})

export default router
