<template>
    <b-modal 
    id="modal-view-service" 
    title="Service Detail"
    @show="onShow"
    size= "xl"
    centered
    hide-footer
    :scrollable="true"
    no-enforce-focus>
        <b-row>
            
            <b-col>
                <b-img class="mt-3" v-bind="mainProps" :src="logo" thumbnail></b-img>
                
            </b-col>
            <b-col>
                <h4>Information</h4>
                <p class="mt-3"><b>Title: </b>{{title}}</p>
                <p class="mt-3" v-html="description"><b>Description: </b><br>{{description}}</p>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import {mapActions, mapState} from "vuex"
export default{
    data(){
        return {
            title: null,
            description: null,
            logo: null,
            mainProps: {
                left: true,
                width: 200
            }
        }
    },
    computed: {
        ...mapState(["service"])
    },
    methods:{
        ...mapActions("service", ["getService"]),

        onShow(){
            this.getService(this.service.selected_service_id).then((response)=>{

                this.title = response.title
                this.description = this.service.service.description
                this.logo = response.logo
            })
        }
    }
}
</script>