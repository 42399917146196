import axios from 'axios'

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

var headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

if (process.env.NODE_ENV === 'production') {
  headers['Access-Control-Allow-Origin'] = 'https://syncdots.id'
}

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: false, // This is the default
  headers
})

const apiClient_formdata = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: {'Content-Type': 'multipart/form-data'}
});

export default {
  getAllArticles(){
    return apiClient.get("/api/v1/blog/articles")
  },
  
  getArticleList (perPage, page) {
    return apiClient.get('/api/v1/blog/articles?page_size=' + perPage + '&page=' + page)
  },

  createArticle (article) {
    return apiClient.post('/api/v1/blog/articles', article)
  },

  updateArticle (article) {
    return apiClient.patch(`/api/v1/blog/articles/${article.slug}`, article)
  },

  getArticle (slug) {
    return apiClient.get(`/api/v1/blog/articles/${slug}`)
  },

  deleteArticle (slug) {
    return apiClient.delete(`/api/v1/blog/articles/${slug}`)
  },

  getTags () {
      return apiClient.get('/api/v1/blog/tags')
  },

  getStatus () {
    return apiClient.get('/api/v1/blog/status')
  },

  getCandidates (perPage, page) {
    return apiClient.get('/api/v1/career/candidate?page_size=' + perPage + '&page=' + page)
  },

  getSkills(){
    return apiClient.get("/api/v1/career/skill_list")
  },

  getExperience(){
    return apiClient.get("/api/v1/career/experience_list")
  },

  getLength(){
    return apiClient.get("/api/v1/career/candidate/")
  },

  getJobListings(){
    return apiClient.get("/api/v1/career/job-listing/")
  },

  createCandidate(formData){
    return apiClient_formdata.post("/api/v1/career/candidate/", formData)
  },

  updateCandidate(formData,candidate){
    console.log(candidate)
    return apiClient_formdata.patch(`/api/v1/career/candidate/${candidate.id}/`, formData)
  },

  deleteCandidate(id){
    return apiClient.delete(`/api/v1/career/candidate/${id}`)
  },

  getCandidate(id){
    return apiClient.get(`/api/v1/career/candidate/${id}`)
  },

  getServices(perPage,page){
    return apiClient.get(`/api/v1/web/service/?page_size=${perPage}&page=${page}`)
  },

  getService(id){
    return apiClient.get(`/api/v1/web/service/${id}`)
  },

  deleteService(id){
    return apiClient.delete(`/api/v1/web/service/${id}`)
  },

  createService(formData){
    return apiClient_formdata.post("api/v1/web/service", formData)
  },

  updateService(formData, id){
    return apiClient_formdata.patch(`/api/v1/web/service/${id}`, formData)
  },


  getProducts(perPage,page){
    return apiClient.get(`/api/v1/web/product/?page_size=${perPage}&page=${page}`)
  },

  getProduct(id){
    return apiClient.get(`/api/v1/web/product/${id}`)
  },

  deleteProduct(id){
    return apiClient.delete(`/api/v1/web/product/${id}`)
  },

  createProduct(formData){
    return apiClient_formdata.post("api/v1/web/product", formData)
  },

  updateProduct(formData, id){
    return apiClient_formdata.patch(`/api/v1/web/product/${id}`, formData)
  },

  getServiceBySlug(slug){
    return apiClient.get(`/api/v1/web/get_service/${slug}`)
  },

  getProductBySlug(slug){
    return apiClient.get(`/api/v1/web/get_product/${slug}`)
  },

  getClients(){
    return apiClient("/api/v1/web/client")
  },

  getTestimonials(){
    return apiClient("/api/v1/web/testimonial")
  },

  getNewses(perPage,page){
    return apiClient.get(`/api/v1/web/news/?page_size=${perPage}&page=${page}`)
  },

  getNews(id){
    return apiClient.get(`/api/v1/web/news/${id}`)
  },

  deleteNews(id){
    return apiClient.delete(`/api/v1/web/news/${id}`)
  },

  createNews(formData){
    return apiClient_formdata.post("api/v1/web/news", formData)
  },

  getNewsBySlug(slug){
    return apiClient.get(`/api/v1/web/get_news/${slug}`)
  },

  getCaseStudies(perPage,page){
    return apiClient.get(`/api/v1/web/case-study/?page_size=${perPage}&page=${page}`)
  },

  getCaseStudy(id){
    return apiClient.get(`/api/v1/web/case-study/${id}`)
  },

  deleteCaseStudy(id){
    return apiClient.delete(`/api/v1/web/case-study/${id}`)
  },

  createCaseStudy(formData){
    return apiClient_formdata.post("api/v1/web/case-study/", formData)
  },

  getCaseStudyBySlug(slug){
    return apiClient.get(`/api/v1/web/get_case_study/${slug}`)
  },
}