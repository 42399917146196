
import ServiceBlog from "../../service/ServiceBlog.js";

export const namespaced = true



export const state = {
    case_study: null,
    case_study_list: [],
    total_case_study: null,
    selected_case_study_id: null
}

export const mutations = {
    ADD_CASE_STUDIES(state, response_data){
        state.case_study_list = response_data.results,
        state.total_case_study = response_data.count
    },

    SELECT_CASESTUDY_ID(state, id){
        state.selected_case_study_id = id
    },

    SELECT_CASESTUDY(state, case_study){
        state.case_study = case_study
    },

    REMOVE_CASESTUDY(state, id){
        state.case_study_list = state.case_study_list.filter(case_study =>{
            return case_study.id != id
        })
    },

    ADD_CASESTUDY(state, case_study){
        state.case_study_list.push(case_study)
    },

    UPDATE_CASESTUDY(state, id){
        state.case_study_list.filter(case_study =>{
            if(case_study.id == id){
                console.log('updated')
            }
        })
    },

    CASESTUDY_BY_SLUG(state){
        console.log('Got service by slug')
    }
}

export const actions = {
    getCaseStudies({commit},{perPage,page}){
        ServiceBlog.getCaseStudies(perPage,page).then((response)=>{
            if(response.status == 200){
                commit('ADD_CASE_STUDIES', response.data)
            }
        })
    },

    getCaseStudy({commit}, id){
        return ServiceBlog.getCaseStudy(id).then((response)=>{
            if(response.status == 200){
                commit('SELECT_CASE_STUDY', response.data)
                return response.data
            }
        })
    },

    deleteCaseStudy({commit}, id){
        return ServiceBlog.deleteCaseStudy(id).then((response)=>{
            if(response.status == 204){
                Vue.toasted.show(`Successfully deleted News`, { className: 'bg-danger' })
                commit('REMOVE_CASE_STUDY', id)
                return response.status
            }
        })
    },

    createCaseStudy({commit}, data){
        return ServiceBlog.createCaseStudy(data.formData).then((response)=>{
            if(response.status == 201){
                Vue.toasted.show(`Successfully created News`, { className: 'bg-success' })
                commit('ADD_CASE_STUDY)', data.case_study)
                return response.status
            }
        })
    },

    updateCaseStudy({commit}, data){
        return ServiceBlog.updateCaseStudy(data.formData, data.id).then((response)=>{
            if(response.status == 200){
                Vue.toasted.show(`Successfully updated News`, { className: 'bg-success' })
                commit('UPDATE_NEWS', data.id)
                return response.status
            }
        })
    },

    getCaseStudyBySlug({commit}, slug){
        return ServiceBlog.getCaseStudyBySlug(slug).then((response)=>{
            if(response.status == 200){
                commit('CASE_STUDY_BY_SLUG')
                return response.data
            }
        })
    }
}