<template>
  <div class="container mt-4 text-justify">
    <Nav></Nav>
    <div class="row">
      <div class="col-lg-6">
        <div class="section-title">
          <h4>NEWS</h4>
        </div>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col cols="10" md="9">
        <div v-for="(news, index) in news.news_list" :key="index">
          <a href="#" @click.prevent="news_details(news.slug)">
            <b-row class="news-items">
              <b-col cols="12">
                <b-row>
                  <b-col md="3">
                    <b-img :src="news.image"></b-img>
                  </b-col>
                  <b-col md="9">
                    <span style="font-size:1.5rem">{{ news.title }}</span><br>
                    <small>DATE: 15th January 2022</small><br>
                    <small>Views: 15K</small>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </a>
        </div>
      </b-col>
    </b-row>
    <!-- <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="totalPage"
      @change="fetchNews"
      use-router
      class="float-right"
    >
    </b-pagination-nav> -->
  </div>
</template>

<script>
import Nav from "../../components/Nav.vue";
import { mapState, mapActions } from "vuex";
import carousel from "vue-owl-carousel";

export default {
  data() {
    return {
      perPage: 20,
    };
  },
  components: {
    Nav,
    carousel,
  },
  computed: {
    ...mapState(["news"]),

    totalPage() {
      if (this.news.total_news % this.perPage !== 0) {
        return this.news.total_news / this.perPage + 1;
      }
      return this.news.total_news / this.perPage;
    },
  },
  methods: {
    ...mapActions("news", ["getNewses"]),
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },

    fetchNewses() {
      this.getNewses({
        perPage: this.perPage,
        page: this.$route.query.page || 1,
      });
    },
    news_details(slug) {
      this.$router.push({
        name: "news-detail",
        params: {
          slug: slug,
        },
      });
    },
  },
  created() {
    this.fetchNewses();
  },
};
</script>

<style scoped>
.latest-img-css {
  background-size: cover;
}
.latest-div {
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.latest-div:hover {
  transform: scale(1.01);
  /* box-shadow: 2px 2px 10px #f27932; */
}
.news-items {
  margin-top: 1rem;
  color: black;
  transition: all 0.3s linear;
  border-bottom: 2px solid transparent;
}
.news-items:hover {
  color: #f27932;
  border-bottom: 2px solid #f27932;
}
@media only screen and (min-width: 756px) {
  .custom-nav {
    margin-top: 1rem;
  }
  .custom-button {
    border-right: 2px #f27932 solid !important;
    background-color: #faf9f6 !important;
    color: #f27932;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  .custom-button:hover {
    background-color: #f27932 !important;
    color: white;
  }
}
</style>
