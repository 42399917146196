<template>
  <div class="container mt-4 text-justify">
    <b-row class="mt-4">
      <b-col md="12" class="mb-1"><h4>CASE STUDIES</h4></b-col>
      <b-col md="12">
        <div
          v-for="(case_study, index) in casestudy.case_study_list"
          :key="index"
        >
          <b-row
            @click.prevent="case_study_details(case_study.slug)"
            v-if="index < 2"
            class="latest-div"
          >
            <b-col md="3">
              <b-img class="latest-img-css" :src="case_study.image"></b-img>
            </b-col>
            <b-col md="9">
              <h3>{{ case_study.title }}</h3>
              <small>DATE: 15th January 2022</small><br />
              <small>Views: 15K</small>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <!-- <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="totalPage"
      @change="fetchNews"
      use-router
      class="float-right"
    >
    </b-pagination-nav> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import carousel from "vue-owl-carousel";

export default {
  data() {
    return {
      perPage: 20,
      caseStudies: [],
    };
  },
  components: {
    carousel,
  },
  computed: {
    ...mapState(["casestudy"]),

    totalPage() {
      if (this.casestudy.total_case_study % this.perPage !== 0) {
        return this.casestudy.total_case_study / this.perPage + 1;
      }
      return this.casestudy.total_case_study / this.perPage;
    },
  },
  methods: {
    ...mapActions("casestudy", ["getCaseStudies"]),
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getValues() {
      this.casestudy.case_study_list.forEach((case_study) => {
        this.caseStudies.push(case_study);
      });
    },
    fetchCaseStudies() {
      this.getCaseStudies({
        perPage: this.perPage,
        page: this.$route.query.page || 1,
      });
    },
    case_study_details(slug) {
      this.$router.push({
        name: "case-study-detail",
        params: {
          slug: slug,
        },
      });
    },
  },
  created() {
    this.fetchCaseStudies();
    setTimeout(() => {
      this.getValues();
    }, 500);
  },
};
</script>

<style scoped>
.latest-img-css {
  background-size: cover;
}
.latest-div {
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.latest-div:hover {
  transform: scale(1.01);
  /* box-shadow: 2px 2px 10px #f27932; */
}
.case-study-items {
  margin-top: 1rem;
  color: black;
  transition: all 0.3s linear;
  border-bottom: 2px solid transparent;
}
.case-study-items:hover {
  color: #f27932;
  border-bottom: 2px solid #f27932;
}
@media only screen and (min-width: 756px) {
  .custom-nav {
    margin-top: 1rem;
  }
  .custom-button {
    border-right: 2px #f27932 solid !important;
    background-color: #faf9f6 !important;
    color: #f27932;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  .custom-button:hover {
    background-color: #f27932 !important;
    color: white;
  }
}
</style>>