<template>
  <div>

    <b-table
      striped bordered hover small
      :items="blog.article_list"
      :fields="fields"
      show-empty
      @row-hovered="rowHovered"
      @row-unhovered="rowUnHovered"
    >
      <template v-slot:cell(title)="row">
        <span class="mr-1">
          {{ row.item.title }}
        </span>
      </template>

      <template v-slot:cell(tags_display)="row">
        <span class="mr-1" v-for="tag in row.item.tags_display" :key="tag.id">
          {{ tag.slug }},
        </span>
      </template>

      <template v-slot:cell(actions)="row">
        <div v-show="selected_row == row.item.slug">
          <b-button size="sm" variant="outline-info" v-b-modal.modal-view-article @click="onView(row.item.slug)">
            <i class="fas fa-eye"></i>
          </b-button>
          <b-button variant="outline-danger" size="sm" @click="onDelete(row.item.slug)">
            <i class="fas fa-trash-alt"></i>  
          </b-button>
          <b-button
            size="sm"
            variant="outline-info"
            v-b-modal.modal-update-article
            @click="onUpdate(row.item.slug)"
            >
              <i class="fas fa-pen-alt"></i>
            </b-button
          >
        </div>
      </template>
    </b-table>
  
    <div class="d-flex justify-content-end">
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="totalPage"
        @change="fetchArticle"
        use-router
      >
      </b-pagination-nav>
    </div>
    <article-view></article-view>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { mapState, mapActions, mapMutations } from "vuex";
import ArticleView from './ArticleView.vue'

export default {
  components:{
    ArticleView
  },
  computed: {},
  data() {
    return {
      selected_row: null,
      perPage: 3,
      options: [],
      status_list: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "bold",
            "italic",
            "BulletedList",
            "NumberedList",
            "undo",
            "redo",
          ],
        },
      },
      fields: [
        {
          key: "date",
          label: "Date",
          sortable: true,
          class: "text-center w-10",
        },
        {
          key: "title",
          label: "Title",
          sortable: true,
          class: "text-left w-25",
        },
        {
          key: "status_display",
          label: "Status",
          sortable: true,
          class: "text-center w-7",
        },
        {
          key: "tags_display",
          label: "Tags",
          sortable: true,
          class: "text-center w-10",
        },
        {
          key: "view_count",
          label: "Views",
          sortable: true,
          class: "text-center w-7",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: true,
          class: "text-center w-15",
        },
      ],
    };
  },
  created() {
      this.fetchArticle()
  },
  computed: {
    ...mapState(["blog"]),

    page() {
      return parseInt(this.$route.query.page) || 1;
    },
    
    totalPage() {
      if (this.blog.total_article % this.perPage !== 0) {
        return this.blog.total_article / this.perPage + 1;
      }
      return this.blog.total_article / this.perPage;
    },
  },
  methods: {
    ...mapMutations("blog", ["SELECT_ARTICLE"]),
    ...mapActions("blog", ["getArticles", "deleteArticle"]),

    rowHovered (record, index) {
      this.selected_row = record.slug
    },

    rowUnHovered () {
        this.selected_row = null
    },

    fetchArticle () {
        this.getArticles({
            perPage: this.perPage,
            page: this.$route.query.page || 1,
        });
    },

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },

    onView(slug){
      this.SELECT_ARTICLE(slug)
    },

    onUpdate(slug) {
      this.SELECT_ARTICLE(slug)
    },

    onDelete(slug) {
      this.$bvModal.msgBoxConfirm('Confirm delete article?', {
          title: 'DELETE ARTICLE',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'CONFIRM',
          cancelTitle: 'CANCEL',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if (value) {
              this.deleteArticle(slug)
            }
          })
          .catch(err => {
            // An error occurred
          })

      // this.deleteArticle(slug)
    },
  },
};
</script>