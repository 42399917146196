<template>
  <div class="mt-4">
    <b-row class="mb-3" >
      <b-col class="text-right">
        <b-button v-b-modal.modal-create-article variant="btn btn-success">
          <b>Create Article</b>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <TableArticle></TableArticle>
      </b-col>
    </b-row>

    <ArticleCreate ></ArticleCreate>
    <ArticleUpdate ></ArticleUpdate>
  </div>
</template>

<script>
import TableArticle from "./TableArticle.vue"
import ArticleCreate from "./ArticleCreate.vue"
import ArticleUpdate from "./ArticleUpdate.vue"

export default {
  components: {
    TableArticle,
    ArticleCreate,
    ArticleUpdate
  },

}
</script>

<style>

</style>