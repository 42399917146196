var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-nav",
    {
      staticClass:
        "navbar navbar-light navbar-expand-lg navbar-custom bg-white shadow-sm fixed-top p-2",
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "router-link",
            { class: "navbar-brand page-scroll", attrs: { to: "/" } },
            [
              _c("img", {
                staticClass: "p-0",
                attrs: {
                  src: "/static/img/syncdots_logo.png",
                  alt: "SyncDots",
                  height: "35",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "navbar-toggler",
              attrs: {
                type: "button",
                "data-toggle": "collapse",
                "data-target": "#navbarSupportedContent",
                "aria-controls": "navbarSupportedContent",
                "aria-expanded": "false",
                "aria-label": "Toggle navigation",
              },
            },
            [_c("span", { staticClass: "navbar-toggler-icon" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "navbarSupportedContent" },
            },
            [
              _c("ul", { staticClass: "navbar-nav ml-auto" }, [
                _c(
                  "li",
                  { staticClass: "nav-item ml-2" },
                  [
                    _c(
                      "router-link",
                      {
                        class: "nav-link bold page-scroll",
                        attrs: { to: "/#services" },
                      },
                      [_c("b", [_vm._v(_vm._s(_vm.$t("Services")))])]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item ml-2" },
                  [
                    _c(
                      "router-link",
                      {
                        class: "nav-link bold page-scroll",
                        attrs: { to: "/product" },
                      },
                      [_c("b", [_vm._v(_vm._s(_vm.$t("Products")))])]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item ml-2" },
                  [
                    _c(
                      "b-link",
                      {
                        class: "nav-link bold page-scroll",
                        attrs: { to: "/blog/" },
                      },
                      [_c("b", [_vm._v(_vm._s(_vm.$t("Blog")))])]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item ml-2" },
                  [
                    _c(
                      "b-link",
                      {
                        class: "nav-link bold page-scroll",
                        attrs: { to: "/news/" },
                      },
                      [_c("b", [_vm._v(_vm._s(_vm.$t("News")))])]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item ml-2" },
                  [
                    _c(
                      "router-link",
                      {
                        class: "nav-link bold page-scroll",
                        attrs: { to: "/#contact" },
                      },
                      [_c("b", [_vm._v(_vm._s(_vm.$t("Contact")))])]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item ml-2 mr-2 mt-1" }, [
                  _c(
                    "a",
                    {
                      staticClass: "p-1 pl-2",
                      staticStyle: {
                        "border-top-left-radius": "10px",
                        "border-bottom-left-radius": "10px",
                        cursor: "pointer",
                      },
                      style: _vm.getActiveCss("en"),
                      on: {
                        click: function ($event) {
                          return _vm.changeLanguage("en")
                        },
                      },
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.$t("EN")))])]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "p-1 pr-2",
                      staticStyle: {
                        "border-top-right-radius": "10px",
                        "border-bottom-right-radius": "10px",
                        cursor: "pointer",
                      },
                      style: _vm.getActiveCss("id"),
                      on: {
                        click: function ($event) {
                          return _vm.changeLanguage("id")
                        },
                      },
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.$t("ID")))])]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item ml-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "get-in-touch",
                      attrs: { onclick: "$crisp.push(['do', 'chat:open'])" },
                    },
                    [
                      _c("b", [
                        _c("i", { staticClass: "far fa-comments" }),
                        _vm._v(" "),
                        _c("b", [_vm._v(_vm._s(_vm.$t("Live Chat")))]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }