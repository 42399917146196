<template>
    <div>
        <b-modal
        id="modal-update-service"
        title="Update Service"
        size="xl"
        centered
        hide-footer
        @hide="onHide"
        @show="onShow"
        :scrollable="true"
        no-enforce-focus>
            <b-form @submit.prevent="onSubmit">
                <b-form-group class="mt-3" id="input-group-1" label="Title" label-for="input-1">
                    <b-form-input
                        id="input-1"
                        v-model="form.title"
                        placeholder="Web Development"
                        required>
                    </b-form-input>
                </b-form-group>

                <b-form-group class="mt-3" id="input-group-2" label="Description" label-for="input-2">
                    <ckeditor :editor="editor" v-model="form.description" :config="editorConfig"></ckeditor>
                </b-form-group>

                <b-form-group class="mt-3" id="input-group-2" label="Brief" label-for="input-2">
                    <ckeditor :editor="editor" v-model="form.brief" :config="editorConfig"></ckeditor>
                </b-form-group>

                <b-row>
                    <b-col cols="12" md="4">
                        <b-form-group class="mt-3" label="Logo">
                            <b-form-file v-model="form.logo" @change="onFileChange" class="mt-3" plain></b-form-file>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group class="mt-3" label="Logo Preview">
                            <img :src="previous_logo_url" :alt="this.form.title" width="100" v-if="previous_logo_url">
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group class="mt-3" label="Previous Logo">
                            <img :src="previous_logo" :alt="this.form.title" width="100" v-if="previous_logo">
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-button class="float-right" style="width:130px;" type="submit" variant="success" :disabled="onProgress">
                    <span v-if="!onProgress">Submit</span>
                    <span v-else>
                      <b-spinner :class="'spinner'"></b-spinner>
                    </span>
                </b-button>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { mapActions, mapState } from 'vuex'
export default{
    data(){
        return{
            onProgress: null,
            previous_logo: null,
            previous_logo_url: null,

            form:{
                title: null,
                description: null,
                brief: null,
                logo: null
            },

            editor: ClassicEditor,
            editorConfig:{
            toolbar: {
                items: [
                    "bold",
                    "italic",
                    "BulletedList",
                    "NumberedList",
                    "undo",
                    "redo"
                    ]
                }
            }
        }
    },

    computed: {
        ...mapState(["service"])
    },

    methods: {
        ...mapActions("service", ["createService", "getServices", "getService", "updateService"]),

        onFileChange(e) {
            const file = e.target.files[0];
            this.previous_logo_url = URL.createObjectURL(file);
        },

        onSubmit(){
            this.onProgress = true
            let formData = new FormData()
            formData.append('title', this.form.title)
            formData.append('description', this.form.description)
            formData.append('brief', this.form.brief)
            if (this.form.logo) {
                formData.append('logo', this.form.logo)
            }

            const data = {
                formData: formData,
                id: this.service.selected_service_id
            }

            this.updateService(data).then((response)=>{
                if(response == 200){
                    this.onReset()
                    this.onProgress = false
                    this.$bvModal.hide("modal-update-service")
                }
            })


        },

        onShow(){
            this.getService(this.service.selected_service_id).then((response)=>{
                this.form.title = response.title
                this.form.description = response.description
                this.form.brief = response.brief
                this.previous_logo = response.logo
            })
        },

        onHide(){
            this.getServices()
        },

        onReset(){
            this.form.title = null,
            this.form.description = null,
            this.form.brief = null,
            this.form.logo = null
        },
    }
}
</script>

<style scoped>
  .spinner {
    height: 1.2rem;
    width: 1.2rem;
  }
</style>