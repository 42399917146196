<template>
  <section class="our-team mt-4">
    <Nav></Nav>
    <!-- <message-from-founder
        image-founder="/static/img/photo_founder.jpeg"
    >
    </message-from-founder> -->

    <div class="container mt-4">
      <div class="row">
          <div class="col-8">
              <h4 class="title">Our Team</h4>
          </div>
          <div class="col-4 text-right">
              <router-link
                class="btn btn-success btn-sm"
                to="/career/"
              >
                  <b>JOIN US</b>
              </router-link>
          </div>
      </div>

      <div class="row">
        <div
          class="col-12 col-md-3 mt-2"
          v-for="index in 4"
          :key="-index"
          v-show="member_list.length == 0"
        >
          <content-loader viewBox="0 0 400 600" height="400">
            <rect x="0" y="0" rx="10" ry="10" width="400" height="400" />
          </content-loader>
        </div>

        <div
          class="col-12 col-md-3"
          v-for="member in member_list"
          :key="member.id"
        >
          <div
            class="
              member-card
              shadow-sm
              animate__animated animate__fadeIn
              mt-2
              p-2
              text-center
            "
            style="height: 100%;"
          >
            <img :src="member.image" :alt="member.name" class="img-member" />
            <div class="mt-2">
              <h5>{{ member.name }}</h5>
              <small>{{ member.designation }}</small>
            </div>
            <div class="text-left m-0 p-0" v-if="member.email || member.contact_no || member.contact_no || member.linkedin">
              <small v-if="member.email">
                <i class="fas fa-envelope-open"></i>
                &nbsp;{{ member.email }} <br /></small
              >
              <small v-if="member.contact_no">
                <i class="fas fa-mobile-alt"></i>
                &nbsp;{{ member.contact_no }} <br /></small
              >
              <span v-if="member.linkedin">
                <a :href="member.linkedin" target="_blank">
                  <i class="fab fa-linkedin fa-lg"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Nav from '../../Nav.vue'
import { ContentLoader } from "vue-content-loader";
import MessageFromFounder from '../team/MessageFromFounder.vue'

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
});

export default {
  components: {
    Nav,
    ContentLoader,
    MessageFromFounder
  },

  data() {
    return {
      member_list: [],
    };
  },

  created() {
    setTimeout(() => {
      apiClient.get("/api/v1/web/team?page_size=100").then((resp) => {
        if (resp.status == 200) {
          this.member_list = resp.data.results;
        }
      });
    }, 300);
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  color: #f27932;
}

.img-member {
  border-radius: 10px;
  width: 250px;
}

.member-card {
  background-color: #fff6f0;
  border-radius: 10px;
}
</style>