var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-create-service",
            title: "Create Service",
            size: "xl",
            centered: "",
            "hide-footer": "",
            scrollable: true,
            "no-enforce-focus": "",
          },
          on: { hide: _vm.onHide, show: function ($event) {} },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mt-3",
                  attrs: {
                    id: "input-group-1",
                    label: "Title:",
                    "label-for": "input-1",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-1",
                      placeholder: "Web Development",
                      required: "",
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "mt-3",
                  attrs: {
                    id: "input-group-2",
                    label: "Description:",
                    "label-for": "input-2",
                  },
                },
                [
                  _c("ckeditor", {
                    attrs: { editor: _vm.editor, config: _vm.editorConfig },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "mt-3",
                  attrs: {
                    id: "input-group-2",
                    label: "Brief:",
                    "label-for": "input-2",
                  },
                },
                [
                  _c("ckeditor", {
                    attrs: { editor: _vm.editor, config: _vm.editorConfig },
                    model: {
                      value: _vm.form.brief,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "brief", $$v)
                      },
                      expression: "form.brief",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-form-file", {
                staticClass: "mt-3",
                attrs: { plain: "" },
                model: {
                  value: _vm.form.logo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "logo", $$v)
                  },
                  expression: "form.logo",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mt-3" }, [
                _vm._v(
                  "Selected file: " +
                    _vm._s(_vm.form.logo ? _vm.form.logo.name : "")
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  staticStyle: { width: "130px" },
                  attrs: {
                    type: "submit",
                    variant: "success",
                    disabled: _vm.onProgress,
                  },
                },
                [
                  !_vm.onProgress
                    ? _c("span", [_vm._v("Submit")])
                    : _c("span", [_c("b-spinner", { class: "spinner" })], 1),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }