var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container mt-4" },
    [
      _c("Nav"),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("div", { staticClass: "section-title" }, [
            _c("h4", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("Products / Portfolio"))),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.product.product_list.length > 0
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.product.product_list, function (product) {
              return _c(
                "div",
                { key: product.id, staticClass: "col-lg-6 float-up mt-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pr-4",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.product_details(product.slug)
                        },
                      },
                    },
                    [
                      _c("product-card", {
                        attrs: {
                          title: product.title,
                          description: product.brief,
                          "img-url": product.logo,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-pagination-nav", {
        staticClass: "float-right mt-4",
        attrs: {
          "link-gen": _vm.linkGen,
          "number-of-pages": _vm.totalPage,
          "use-router": "",
        },
        on: { change: _vm.fetchProducts },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }