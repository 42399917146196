<template>
  <section id="case-studies" class="products-area" style="position: relative">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <div class="section-title">
            <h4 class="title">CASE STUDIES</h4>
          </div>
          <!-- section title -->
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="section-title text-right" style="margin-right: 1.2rem">
            <b-link :to="{ path: '/case-study/' }" class="w-30">
              <b-button class="see-all-button w-100"> SEE ALL </b-button>
            </b-link>
          </div>
          <!-- section title -->
        </div>
      </div>

      <div v-if="caseStudies.length > 0">
        <carousel
          id="owl-carousel"
          :autoplay="false"
          :autoplayHoverPause="false"
          :center="false"
          :nav="false"
          :items="3"
          :autoplayTimeout="5000"
          :dots="false"
        >
          <template slot="prev">
            <span class="casestudy-carousal-previous">
              <i class="fas fa-angle-left fa-2x"></i>
            </span>
          </template>
          <div
            class="mt-2 mr-4 bg-white rounded shadow-sm p-2"
            style="cursor:pointer;"
            v-for="(case_study, index) in caseStudies"
            :key="index"
            @click.prevent="case_study_details(case_study.slug)"
          >
            <b-row>
              <b-col md="12">
                <b-img
                  class="casestudy-img-style"
                  :src="case_study.image"
                ></b-img>
              </b-col>
              <b-col md="12">
                <span style="color:#f27932;">
                  {{ case_study.title }}
                </span>
              </b-col>
            </b-row>
          </div>

          <template slot="next" v-if="caseStudies.length > 2">
            <span class="casestudy-carousal-next">
              <i class="fas fa-angle-right fa-2x"></i>
            </span>
          </template>
        </carousel>
      </div>

      <!-- <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="totalPage"
      @change="fetchServices"
      use-router
      class="float-right mt-2"
    >
    </b-pagination-nav> -->
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import carousel from "vue-owl-carousel";

export default {
  data() {
    return {
      perPage: 10,
      caseStudies: [],
    };
  },
  components: {
    carousel,
  },
  computed: {
    ...mapState(["casestudy"]),
    page() {
      return parseInt(this.$route.query.page) || 1;
    },

    totalPage() {
      if (this.casestudy.total_case_study % this.perPage !== 0) {
        return this.casestudy.total_case_study / this.perPage + 1;
      }
      return this.casestudy.total_case_study / this.perPage;
    },
  },
  methods: {
    ...mapActions("casestudy", ["getCaseStudies"]),
    getValues() {
      this.casestudy.case_study_list.forEach((case_study) => {
        this.caseStudies.push(case_study);
      });
    },
    getShortDescription(description) {
      if (description.length > 100) {
        return description.slice(0, 100);
      }
      return description;
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },

    fetchCaseStudies() {
      this.getCaseStudies({
        perPage: this.perPage,
        page: this.$route.query.page || 1,
      });
    },

    case_study_details(slug) {
      this.$router.push({
        name: "case-study-detail",
        params: {
          slug: slug,
        },
      });
    },
  },
  created() {
    this.fetchCaseStudies();
    setTimeout(() => {
      this.getValues();
    }, 500);
  },
};
</script>

<style>
.decor {
  color: black !important;
  font-size: 1.3rem;
  /* margin: 0.5rem 0; */
}
.casestudy-img-style {
  width: 10rem;
  border-radius: 10px;
}
.casestudy-carousal-previous {
  position: absolute;
  top: 8rem;
  margin-left: -4rem;
  font-size: 1.5rem;
  cursor: pointer;
}
.casestudy-carousal-previous:hover {
  color: #f27932;
}
.casestudy-carousal-next {
  position: absolute;
  top: 8rem;
  margin-left: 70rem;
  font-size: 1.5rem;
  cursor: pointer;
}
.casestudy-carousal-next:hover {
  color: #f27932;
}
</style>