<template>
    <div>
        <b-modal
        class="container" 
        id="modal-view-candidate" @show="onShow"
        title = "Candidate Info"
        size= "xl"
        centered
        hide-footer
        :scrollable="true"
        no-enforce-focus>
            <b-row>
                <b-col>
                    <h4>Personal Info</h4>
                    <b-row>
                        <b-col class="mt-3">
                            <p>ID</p>
                            <p>Name</p>
                            <p>Phone No</p>
                            <p>Identification No</p>
                            <p>Email</p>
                            <p>Address</p>
                        </b-col>
                        <b-col class="mt-3">
                            <p>{{id}}</p>
                            <p>{{name}}</p>
                            <p>{{phone_no}}</p>
                            <p>{{identification_no}}</p>
                            <p>{{email}}</p>
                            <p>{{address}}</p>
                            
                        </b-col>
                    </b-row>
                </b-col>
                <b-col >
                    <h4>Profile</h4>
                    <b-row class="mt-3">
                        <b-col>
                            <p>LinkedIn</p>
                            <p>GitHub</p>
                            <p>Website</p>
                            <p>Resume</p>
                        </b-col>
                        <b-col>
                            <p><a href="#">{{linkedin_url}}</a></p>
                            <p><a href="#">{{github_url}}</a></p>
                            <p><a href="#">{{website_url}}</a></p>
                            <p><a href="#">{{resume}}</a></p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>
                    <h4>About</h4>
                    <p class="mt-3">{{about_self}}</p>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <b-col class="mt-3">
                    <h4>Work Experience</h4>
                    <div class="wexp">
                        <div v-for="exp in work_exps" :key="exp.id" class="card">
                            <p><b>Institute:</b> {{exp.institute_name}}</p>
                            <p><b>Position:</b> {{exp.position}}</p>
                            <p><b>From:</b> {{exp.start_time}}</p>
                            <p v-if="exp.end_time"><b>To:</b> {{exp.end_time}}</p>
                            <p v-else><b>To:</b> Present</p>
                        </div>
                    </div>
                </b-col>
                <b-col class="mt-3">
                    <h4>Skills</h4>
                    <div class="wexp">
                        <div v-for="skill in skills" :key="skill.id" class="card">
                            <p><b>Topic:</b> {{skill.topic_display}}</p>
                            <p><b>Experience:</b> {{skill.exp_display}}</p>
                            
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>
                    <h4 class="mb-3">Cover Letter</h4>
                    <span v-html="cover_letter"></span>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import {mapActions} from "vuex"
export default {
    data(){
        return{
            id: null,
            candidate_id: null,
            name: null,
            identification_no: null,
            email: null,
            phone_no: null,
            address: null,
            linkedin_url: null,
            github_url: null,
            website_url: null,
            about_self: null,
            resume: null,
            cover_letter: null,
            job_listing: null,
            skills: [],
            work_exps: [],

        }
    },
    methods: {
        ...mapActions("candidate", ["getCandidate"]),

        onShow(){
            this.getCandidate(this.$store.state.candidate.selected_candidate_id).then((response)=> {
                this.id = response.id,
                this.candidate_id = response.candidate_id,
                this.name = response.name,
                this.identification_no = response.identification_no,
                this.email = response.email,
                this.phone_no = response.phone_no,
                this.address = response.address,
                this.linkedin_url = response.linkedin_url,
                this.github_url = response.github_url,
                this.website_url = response.website_url,
                this.about_self = response.about_self,
                this.resume = response.resume,
                this.cover_letter = response.cover_letter,
                this.job_listing = response.job_listing,
                
                response.skills.forEach(skill => {
                    this.skills.push(skill)
                });

                response.work_exps.forEach(exp => {
                    this.work_exps.push(exp)
                });

                
                console.log(this.candidate)
            })
        }
    }
}
</script>

<style scoped>
*{
    font-size: 14px;
}
.wexp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 10px 16px;
  width: 250px;
  margin: 10px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
</style>
